<template>
	<div>
		<div class="card flex-row align-items-center justify-content-between">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 p-2" role="tabslits">
				<b-nav-item
					v-for="(tab, index) in countersMatch"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:key="`${index}-index`"
					:active="indexTabChick == index"
					@click="
						indexTabChick = index;
						tanIndexMatch = tab.id;
						init();
					"
					:disabled="tab.counter == 0"
				>
					<span>{{ tab.description }}</span>
					<b-badge v-if="tab.count > 0" pill variant="danger" class="ml-1">
						{{ tab.count }}
					</b-badge>
				</b-nav-item>
			</ul>
			<!-- <button @click="sidebarCrearInventario.show = true" class="btn btn-primary mr-2">Agregar</button> -->
		</div>

		<div class="card p-2">
			<div class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-column align-items-end">
							<div class="xt-search-input__paginate mt-1 mt-md-0">
								<b-pagination
									:value="page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
									@input="
										(e) =>
											SET_FILTERS({
												search: search,
												page: e,
											})
									"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search w-100">
								<div class="d-flex flex-column flex-md-row align-items-end justify-content-md-end">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="searchInput"
										placeholder="Buscar cintillo"
										style="width: 200px"
										@input="
											SET_FILTERS({
												search: searchInput,
												page: page,
											})
										"
									/>
									<div class="search-btn ml-0 ml-md-1 mt-1 mt-md-0">
										<button @click="init" class="btn btn-primary w-100">Buscar</button>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>

		<div class="card">
			<template v-if="match.length > 0">
				<div class="container-chicks-match p-2">
					<CardChickInventory
						@resurrect="resurrect"
						:chick="chick"
						v-for="(chick, index) in match"
						:key="index"
						:index="index"
						@dead="deadChick"
					/>
				</div>
			</template>
			<template v-else>
				<div class="text-center mt-2 mb-2">
					<p class="font-small-4 mb-0 font-weight-bolder">Sin ejemplares por mostrar</p>
				</div>
			</template>
		</div>

		<AddDeadCPCA
			@set-reload="changeReloadRecursive = true"
			v-if="selectDeadChick.open"
			:postura="{
				ca: {
					id: selectDeadChick.ca.id,
					plate: selectDeadChick.ca.plate,
					status: selectDeadChick.ca.status,
				},
				cintillos: {
					cp_id: null,
				},
				egg_lote_id: null,
			}"
			@hidden="closeAddDeadCPCA"
		/>
	</div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import ChickensList from "@/views/amg/encaste/chickens/ChickensList.vue";
// import ChickensListInventory from "./ChickensListInventory.vue";
// import CreateChicksMassively from "./CreateChicksMassively.vue";
import CardChickInventory from "../../specimens/components/CardChickInventory.vue";
import deadsService from "@/services/deads.service";
import AddDeadCPCA from "@/views/amg/encaste/lotes/modals/AddDeadCPCA.vue";

export default {
	name: "TableChicksUnMatch",
	components: {
		ChickensList,
		// ChickensListInventory,
		// CreateChicksMassively,
		CardChickInventory,
		AddDeadCPCA,
	},
	props: {
		inventory_id: {
			type: Number,
		},
	},
	mounted() {
		// console.log(this.$route.paramsinventory_id);
	},
	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true },
				{ key: "fecha", title: "Fecha", visible: true },
				{ key: "plates", title: "Placas ingresadas", visible: true },
				{ key: "match", title: "#Coinciden", visible: true },
				{ key: "unmatch", title: "#No coinciden", visible: true },
				{ key: "status", title: "Estado", visible: true },
				{ key: "usuario", title: "Creado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			specimens: [],
			searchInput: null,
			indexTabChick: 0,
			tanIndexMatch: null,
			selectDeadChick: {
				open: false,
				ca: {
					id: null,
					plate: null,
				},
			},
			changeReloadRecursive: false,
		};
	},
	computed: {
		module() {
			return this.$route.meta.module;
		},
		...mapState("inventorySpecimens", [
			"page",
			"perpage",
			"to_page",
			"total_data",
			"start_page",
			"search",
			"match",
			"unmatch",
			"countersMatch",
		]),
	},
	async mounted() {
		await this.init();
	},
	methods: {
		...mapMutations("inventorySpecimens", ["UPDATE_INDEX_TAB", "SET_FILTERS","CLEAN_DATA"]),
		...mapActions("inventorySpecimens", ["getCounterInventory", "getDetails"]),
		setTab(index) {
			this.UPDATE_INDEX_TAB({ index });
			this.SET_FILTERS({
				search: null,
				page: 1,
			});
		},
		async init() {
			this.CLEAN_DATA();
			this.isPreloading();
			// this.CLEAN_SPECIMENS();
			await this.getDetails({
				id: this.inventory_id,
				module: this.module,
				page: this.page,
				perpage: this.perpage,
				search: this.search,
				type: 1,
				status_id: this.tanIndexMatch,
			});
			if (this.countersMatch.map((it) => it.id).includes(this.tanIndexMatch)) {
				// this.indexTabChick = this.indexTabChick;
			} else {
				this.indexTabChick = 0;
				this.tanIndexMatch = null
			}

			this.isPreloading(false);
		},
		async resurrect(chick) {
			console.log("resp");
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const {
				data: { message, status },
			} = await deadsService.toReviveCACP({
				id: chick.id,
				type: 2,
			});

			this.isPreloading(false);

			if (status) {
				this.showToast("success", "top-right", "Inventario pollos", "SuccessIcon", message);
				await this.init();
				await this.getCounterInventory({
					id: this.$route.params.inventory_id,
					module: this.module,
					matchIndex: this.$route.query.match,
				});
			}
		},
		async closeAddDeadCPCA() {
			this.selectDeadChick.open = false;
			this.selectDeadChick.ca = {
				id: null,
				plate: null,
			};

			this.globalDeadStatusModal = false;
			this.globalDeadStatus = null;

			await this.init();
			await this.getCounterInventory({
				id: this.$route.params.inventory_id,
				module: this.module,
				matchIndex: this.$route.query.match,
			});
		},
		deadChick(item) {
			this.selectDeadChick.open = true;
			this.selectDeadChick.ca = {
				id: item.id,
				plate: item.correlative,
				status: 3,
			};
		},
	},
	watch: {
		async page() {
			await this.init();
		},
		async indexTabChick() {
			await this.init();
		},
	},
};
</script>

<style lang="scss" scoped>
.container-chicks-match {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;

	@media screen and (min-width: 500px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: 800px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and (min-width: 1200px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(3, 1fr);
	}
}
</style>
