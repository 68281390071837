<template>
	<div>
		<div class="card p-2">
			<div class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-column align-items-end">
							<div class="xt-search-input__paginate mt-1 mt-md-0">
								<b-pagination
									:value="page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
									@input="
										(e) =>
											SET_FILTERS({
												search: search,
												page: e,
											})
									"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search w-100">
								<div class="d-flex flex-column flex-md-row align-items-end justify-content-md-end">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="searchInput"
										:placeholder="module == 1 ? `Placa o Alias` : 'Buscar cintillo'"
										style="width: 200px"
										@input="
											SET_FILTERS({
												search: searchInput,
												page: page,
											})
										"
									/>
									<div class="search-btn ml-0 ml-md-1 mt-1 mt-md-0">
										<button @click="init" class="btn btn-primary w-100">Buscar</button>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<!-- <div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
					<b-thead>
						<b-tr>
							<b-th v-if="title.visible" v-for="(title, key) in headTable" :key="key">
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1">{{ title.title }}</p>
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr
							v-if="specimens.length > 0"
							class="text-center"
							v-for="(item, index) in specimens"
							:key="index"
						>
							<b-td class="">
								<p class="mb-0 font-weight-bolder">{{ item.code }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ item.date }}</p>
							</b-td>

							<b-td class="text-center">
								<p class="mb-0 count-inventory bg-success">{{ item.count }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0 count-inventory bg-warning" @click="setActionInventory(item.id, 0)">
									{{ item.match }}
								</p>
							</b-td>

							<b-td class="">
								<p class="mb-0 count-inventory bg-danger" @click="setActionInventory(item.id, 1)">
									{{ item.unmatch }}
								</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ item.status }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ item.user }}</p>
							</b-td>

							<b-td class="">
								<b-button-group>
									<b-button variant="success">
										<FeatherIcon icon="XIcon" size="18" class="icon text-white" />
									</b-button>
									<b-button>
										<FeatherIcon icon="XIcon" size="18" class="icon text-white" />
									</b-button>
									<b-button variant="danger">
										<FeatherIcon icon="XIcon" size="18" class="icon text-white" />
									</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr v-if="specimens.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin specimens encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div> -->

			<template v-if="match.length > 0">
				<div class="container-specimens-match mt-1" v-if="module == 1">
					<CardSpecimenInventory
						v-for="(specimen, index) in match"
						:key="index"
						:specimen="{
							...specimen,
							check: false,
						}"
						:index="index"
						:check_disabled="false"
					/>
				</div>
			</template>
			<template v-else>
				<div class="text-center">
					<p class="font-small-4 mb-0 font-weight-bolder">Sin ejemplares por mostrar</p>
				</div>
			</template>

			<div class="container-chicks-match" v-if="module == 2">
				<CardChickInventory v-for="(chick, index) in match" :chick="chick" :key="index" visible_resurect/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import CardSpecimenInventory from "./CardSpecimenInventory.vue";
import CardChickInventory from "./CardChickInventory.vue";

export default {
	name: "TableSpecimensMatch",
	components: {
		CardSpecimenInventory,
		CardChickInventory,
	},
	props: {
		inventory_id: {
			type: Number,
		},
	},
	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true },
				{ key: "fecha", title: "Fecha", visible: true },
				{ key: "plates", title: "Placas ingresadas", visible: true },
				{ key: "match", title: "#Coinciden", visible: true },
				{ key: "unmatch", title: "#No coinciden", visible: true },
				{ key: "status", title: "Estado", visible: true },
				{ key: "usuario", title: "Creado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			specimens: [],
			searchInput: null,
		};
	},
	computed: {
		...mapState("inventorySpecimens", [
			"page",
			"perpage",
			"to_page",
			"total_data",
			"start_page",
			"search",
			"match",
		]),
		module() {
			return this.$route.meta.module;
		},
	},
	async mounted() {
		await this.init();
	},
	methods: {
		...mapActions("inventorySpecimens", ["getMatch", "getDetails"]),
		...mapMutations("inventorySpecimens", ["SET_FILTERS"]),
		async init() {
			await this.getDetails({
				id: this.inventory_id,
				module: this.module,
				page: this.page,
				perpage: this.perpage,
				search: this.search,
				type: 1,
			});
		},
	},
	watch: {
		async page() {
			await this.init();
		},
	},
};
</script>

<style lang="scss" scoped>
.search-input {
	width: 100% !important;
	@media screen and (min-width: 900px) {
		width: 200px !important;
	}
}
.search-btn {
	width: 100% !important;
	@media screen and (min-width: 900px) {
		width: 120px !important;
	}
}
.container-specimens-match {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;

	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(5, 1fr);
	}
}

.container-chicks-match {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;

	@media screen and (min-width: 500px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: 800px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and (min-width: 1000px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.card-specimen-inventory {
	background: #f5f5f5;
	border-radius: 12px;
}
</style>
