var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card p-2"},[_c('div',{staticClass:"mx-2 m-2 position-relative"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start justify-content-sm-start",attrs:{"cols":"12","md":"12","lg":"4"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" Mostrando "+_vm._s(_vm.start_page)+" a "+_vm._s(_vm.to_page)+" de "+_vm._s(_vm.total_data)+" registros ")])]),_c('b-col',{staticClass:"flex-nowrap",attrs:{"cols":"12","md":"12","lg":"8"}},[_c('div',{staticClass:"flex flex-column align-items-end"},[_c('div',{staticClass:"xt-search-input__paginate mt-1 mt-md-0"},[_c('b-pagination',{attrs:{"value":_vm.page,"total-rows":_vm.total_data,"per-page":_vm.perpage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (e) { return _vm.SET_FILTERS({
											search: _vm.search,
											page: e,
										}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}])})],1),_c('div',{staticClass:"xt-search-input__search w-100"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-end justify-content-md-end"},[_c('b-form-input',{staticClass:"search-input",staticStyle:{"width":"200px"},attrs:{"type":"search","placeholder":_vm.module == 1 ? "Placa o Alias" : 'Buscar cintillo'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.init($event)},"input":function($event){return _vm.SET_FILTERS({
											search: _vm.searchInput,
											page: _vm.page,
										})}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('div',{staticClass:"search-btn ml-0 ml-md-1 mt-1 mt-md-0"},[_c('button',{staticClass:"btn btn-primary w-100",on:{"click":_vm.init}},[_vm._v("Buscar")])])],1)])])])],1)],1),(_vm.match.length > 0)?[(_vm.module == 1)?_c('div',{staticClass:"container-specimens-match mt-1"},_vm._l((_vm.match),function(specimen,index){return _c('CardSpecimenInventory',{key:index,attrs:{"specimen":Object.assign({}, specimen,
						{check: false}),"index":index,"check_disabled":false}})}),1):_vm._e()]:[_vm._m(0)],(_vm.module == 2)?_c('div',{staticClass:"container-chicks-match"},_vm._l((_vm.match),function(chick,index){return _c('CardChickInventory',{key:index,attrs:{"chick":chick,"visible_resurect":""}})}),1):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"font-small-4 mb-0 font-weight-bolder"},[_vm._v("Sin ejemplares por mostrar")])])}]

export { render, staticRenderFns }