<template>
	<div>
		<b-row>
			<b-col md="2">
				<b-form-group label="Placa">
					<b-form-input v-model="spec.plate" placeholder="Agregar placa" disabled></b-form-input>
				</b-form-group>
			</b-col>
			<b-col md="3">
				<b-form-group label="Cambiar por">
					<!-- {{spec.newPlateSpecimen}} -->
					<ValidationProvider :rules="spec.check ? 'required' : ''" v-slot="{ errors }">
						<v-select
							ref="searchCA"
							class="w-100 mb-0"
							v-model="spec.newSpecimen"
							:options="specimensSearch"
							label="plate"
							:reduce="(option) => option"
							transition="plate"
							placeholder="Buscar ejemplares"
							:clearable="false"
							@input="(e) => (spec.newPlateSpecimen = e.plate)"
							@search="
								(search, loading) =>
									getSpecimens({
										search,
										loading,
									})
							"
							:disabled="!spec.check"
							:class="{ 'border-danger rounded': errors[0] }"
						>
							<template slot="no-options"> Sin ejemplares encontrados </template>

							<template slot="option" slot-scope="option">
								<div class="d-center">
									{{ option.plate }} / {{ option.alias }} -
									{{ option.status }}
								</div>
							</template>
							<template slot="selected-option" slot-scope="option">
								<div class="selected d-center">
									{{ option.plate }} / {{ option.alias }} -
									{{ option.status }}
								</div>
							</template>
							
						</v-select>
						<small :class="[errors[0]? 'text-danger' : '']">{{ errors[0] }}</small>
					</ValidationProvider>
				</b-form-group>
			</b-col>
			<b-col md="6">
				<SpecimenStatusChangedBody
					v-if="spec.newSpecimen && spec.newSpecimen.status_id != 1"
					class="w-100"
					:specimen="spec"
					:arrayDeads="arrayDeads"
					:arrayReasons="arrayReasons"
					:arrayStatus="arrayStatus"
					:arrayDiseases="arrayDiseases"
					:arrayUsers="arrayUsers"
					:arrayClients="arrayClients"
					:isEditingAdmin="false"
					:isEditingStatusSpecimen="false"
					isCreatingNewSpecimen
					:isInventory="isInventory"
				/>
			</b-col>
		</b-row>

		<b-row class="w-100"> </b-row>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SpecimenStatusChangedBody from "./components/SpecimenStatusChangedBody.vue";
import inventoryService from "@/services/inventory.service";

export default {
	name: "UpdatePlateAndStatusSpecimen",
	components: {
		SpecimenStatusChangedBody,
	},
	props: {
		spec: {
			type: Object,
			default: () => {},
		},
		isInventory: {
			type: Boolean,
		}
	},
	data() {
		return {
			arrayDeads: [
				{
					type: 3,
					id: null,
					observation: null,
					circumstances: null,
					evidences: [],
					customFormData: [],
					breeder_id: null, // criador
					others_description: null,
				},
			],
			specimensSearch: [],
		};
	},
	async mounted() {},
	computed: {
		...mapState("statusSpecimens", [
			"selectedSpecimensStatus",
			"arrayUsers",
			"arrayStatus",
			"arrayReasons",
			"arrayDiseases",
			"arrayClients",
		]),
	},
	methods: {
		...mapActions("statusSpecimens", [
			"getUsersByRole",
			"getReasons",
			"getClients",
			"getDiseases",
			"getAllStatusSpecimens",
			"A_SAVE_STATUS_MASSIVELY",
		]),
		async getSpecimens({ search, loading }) {
			loading(true);
			if (search.length > 1) {
				const { data, message } = await inventoryService.getEntity({
					module: 1,
					search,
					id: this.$route.params.inventory_id,
				});

				this.specimensSearch = data;
			}
			loading(false);
		},
	},
};
</script>
