var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table-simple',{staticClass:"table-change",staticStyle:{"min-height":"50vh"},attrs:{"sticky-header":"75vh","striped":"","responsive":"","bordered":""}},[_c('b-thead',{attrs:{"head-variant":"light"}},[_c('b-tr',{staticClass:"text-center"},[(_vm.isInventory)?_c('b-th',{staticClass:"text-center check-container"},[_c('div',{staticClass:"check"},[_c('b-form-checkbox',{staticClass:"text-center pl-0 input",attrs:{"checked":_vm.checkAll},on:{"change":_vm.checkAllTotal}})],1)]):_c('b-th',{staticClass:"p-1"},[_vm._v("#")]),_c('b-th',{staticClass:"p-1"},[_vm._v("Gallo")]),_c('b-th',{staticClass:"p-1"},[_vm._v("Estado Actual")]),_c('b-th',{staticClass:"p-1"},[_vm._v("Nuevo Estado")]),_c('b-th',{staticClass:"p-1"},[_vm._v("Campos")]),_c('b-th',{staticClass:"p-1"},[_vm._v("Fecha")]),(!_vm.isInventory)?_c('b-th',{staticClass:"p-1 text-center"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Eliminar todo'),expression:"'Eliminar todo'",modifiers:{"hover":true}}]},[_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"Trash2Icon","size":"20"},on:{"click":function($event){return _vm.CLEAN_SPECIMENS()}}})],1)]):_vm._e()],1)],1),_c('b-tbody',_vm._l((_vm.selectedSpecimensStatus),function(specimen,index){return _c('b-tr',{key:("specimen-" + index),staticClass:"p-50 text-center"},[(_vm.isInventory)?_c('b-th',{staticClass:"text-center check-container"},[_c('div',{staticClass:"check"},[_c('b-form-checkbox',{staticClass:"text-center pl-0 input",attrs:{"checked":specimen.check},on:{"change":function($event){return _vm.M_TOGGLE_CHECK_SPECIMEN({ check: !specimen.check, key: index })}}})],1)]):_c('b-th',{staticClass:"p-1 text-center"},[_vm._v(_vm._s(index + 1))]),_c('b-th',{staticClass:"p-1"},[_c('SpecimenPlate',{attrs:{"specimen":{
						id: specimen.specimen_id,
						plate: specimen.plate,
						alias: specimen.alias,
						thumb: specimen.thumb,
						image: specimen.photo,
					}}})],1),_c('b-th',{staticClass:"p-1 text-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center gap-x-1"},[_c('span',[([0, 1].includes(specimen.in_galpon))?[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(specimen.in_galpon === 1 ? 'Disponible' : 'No disponible'),expression:"specimen.in_galpon === 1 ? 'Disponible' : 'No disponible'",modifiers:{"hover":true,"top":true}}],class:{
									'text-danger': specimen.in_galpon === 0,
									'text-success': specimen.in_galpon === 1,
								},attrs:{"size":"18","icon":"CheckCircleIcon"}})]:_vm._e()],2),_c('span',{staticStyle:{"font-weight":"lighter"}},[_vm._v(" ("+_vm._s(specimen.status_description || specimen.status_id)+") ")])])]),_c('b-th',{staticClass:"p-1"},[(specimen.statuses.length > 0)?_c('SelectStatusRooster',{attrs:{"index":index,"is_header":_vm.is_header,"specimen":specimen,"in_galpon":_vm.in_galpon,"isInventory":_vm.isInventory}}):_vm._e()],1),_c('b-th',{staticClass:"p-1"},[_c('DiferentsFieldStatus',{attrs:{"specimen":specimen,"isInventory":_vm.isInventory}})],1),_c('b-th',{staticClass:"p-1"},[_c('b-form-datepicker',{attrs:{"id":("date-" + index),"placeholder":"Fecha","date-format-options":{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					},"disabled":'inventory-specimens-by-id-sr' == _vm.$route.name ? !specimen.check : false},model:{value:(specimen.date_status),callback:function ($$v) {_vm.$set(specimen, "date_status", $$v)},expression:"specimen.date_status"}})],1),(!_vm.isInventory)?_c('b-th',{staticClass:"text-center p-50"},[_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"Trash2Icon"},on:{"click":function($event){return _vm.deleteRooster(specimen, index)}}})],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }