<template>
	<div class="container-chicks" v-if="specimen">
		<div class="header">
			<p class="mb-0" :style="{color: !specimen.alias ? '#ff9b3c' : ''}">{{ specimen.alias || "Sin alias" }}</p>
			<b-form-checkbox
				v-if="check_disabled"
				:value="specimen.check"
			>
			</b-form-checkbox>
		</div>
		<div class="body">
			<div class="details">
				<div class="details-item">
					<p class="mb-0">Estado:</p>
					<span>{{ specimen.status }}</span>
				</div>
				<div class="details-item">
					<p class="mb-0">Genero:</p>
					<span>{{ specimen.gender }}</span>
				</div>
			</div>
			<div class="parents">
				<div class="parents-item">
					<!-- <p class="title mb-0">Madrilla</p> -->
					<img onerror="this.src='/rooster/rooster.jpg'" class="image" :src="specimen.thumb ? specimen.thumb : '/rooster/rooster.jpg'" alt="" />
					<!-- <p class="alias mb-0">{{ specimen.alias }}</p> -->
					<span class="plate mb-0">{{ specimen.plate }}</span>
				</div>

				<!-- <div class="parents-item">
					<p class="title mb-0">Padrillo</p>
					<img onerror="this.src='/rooster/rooster.jpg'" class="image" :src="chick.padrillo.thumb" alt="" />
					<p class="alias mb-0">{{ chick.padrillo.alias }}</p>
					<span class="plate mb-0">{{ chick.padrillo.plate }}</span>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	name: "CardSpecimenInventory",
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
		},
		check_disabled: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			bool: false,
		};
	},
	methods: {
		...mapMutations("inventorySpecimens", ["TOGGLE_CHECK_SPECIMEN_MATCH"]),
	},
};
</script>

<style lang="scss" scoped>
.container-chicks {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	border-radius: 8px;

	.header {
		overflow: hidden;
		background: #f3f2f7;
		padding: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			font-weight: bolder;
			font-size: 16px;
		}
		border-left: 2px solid #d8d7da;
		border-right: 2px solid #d8d7da;
		border-top: 2px solid #d8d7da;
		border-radius: 8px 8px 0 0;
	}

	.body {
		// overflow: hidden;
		border: 2px solid #d8d7da;
		// padding: 1.5rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		.details {
			padding: 1.5rem;
			.details-item {
				margin: 1rem 0;
				// display: flex;
				p {
					// width: 80px;
					font-weight: bolder;
				}
				span {
				}
			}
		}
		.parents {
			padding: 1rem 1rem;
			border-left: 1.8px solid #cccccc;
			display: grid;
			grid-template-columns: 1fr;
			.parents-item {
				// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				.title {
					color: #6d6d6d;
					font-weight: 600;
				}
				.image {
					border-radius: 8px;
					height: 100px;
					width: 100px;
					margin-bottom: 0.5rem !important;
				}
				.alias {
					color: #6d6d6d;
					font-weight: 500;
					margin: 0.2rem 0 !important;
				}
				.plate {
					// margin-top: 2rem !important;
					background: #7367ef;
					color: #fff;
					border: 12px;
					font-weight: bolder;
					// width: auto;
					padding: 0.2rem 1rem;
					border-radius: 12px;
				}
			}
		}
	}
}
</style>
