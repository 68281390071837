<template>
	<div class="p-1 p-md-2">
		<validation-observer ref="form">
			<div class="d-flex flex-column">
				<div class="container-btn-save" v-if="updatedByUser">
					<button v-if="!inventory.id" class="btn btn-primary" @click="createInventory">
						<FeatherIcon icon="SaveIcon" size="14" class="mr-25" /> Guardar
					</button>
					<button v-else class="btn btn-primary" @click="updateInventoryAndAddPlates">
						<FeatherIcon icon="SaveIcon" size="14" class="mr-25" /> Guardar
					</button>
				</div>
				<div class="container-form" :style="{ position: 'relative' }">
					<h3>Información</h3>
					<div class="d-flex align-items-center">
						<b-form-group v-if="module == 1" label="Bloque (Alias)" class="input-alias-add">
							<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
								<b-form-input
									:invalid-feedback="errors[0]"
									:state="errors[0] ? false : null"
									v-model="alias"
									placeholder="Ingresar bloque"
									@input="updatedByUser = true"
								></b-form-input>
								<small v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>

						<b-form-group v-if="module == 1" label="Fecha de inicio" class="input-alias-add ml-1">
							<b-form-datepicker
								placeholder="Ingresar fecha"
								v-model="inventory.start_date"
								:date-format-options="{}"
								locale="es"
								:disabled="disabledSave"
								@input="updatedByUser = true"
							></b-form-datepicker>
						</b-form-group>
					</div>

					<b-form-group label="Comentario" class="container-comment">
						<b-form-textarea
							v-model="comment"
							placeholder="Ingresar comentario"
							rows="3"
							max-rows="6"
						></b-form-textarea>
					</b-form-group>
				</div>

				<div class="container-form">
					<h3>Placas</h3>

					<!-- setPlate(plate) -->
					<b-form-group label="Placa">
						<b-form-input
							class="input-alias-add"
							placeholder="Ingresar placa"
							@keyup.enter="validaPlateSpecimen(plate)"
							:value="plate"
							:disabled="disabledSave"
							@input="(e) => escapeRegExp(e)"
						></b-form-input>
					</b-form-group>

					<div class="mx-2 m-2 position-relative">
						<b-row>
							<b-col
								cols="12"
								md="12"
								lg="4"
								class="d-flex align-items-center justify-content-start justify-content-sm-start"
							>
								<span class="text-muted" v-if="inventory.value.length > 0">
									Mostrando {{ perpage * (page - 1) + 1 }} a
									{{
										perpage * (page - 1) + perpage + 1 <= total_data
											? perpage * (page - 1) + perpage + 1
											: total_data
									}}
									de {{ total_data }} registros
								</span>
							</b-col>
							<!-- Pagination -->
							<b-col cols="12" md="12" lg="8" class="flex-nowrap">
								<div class="flex flex-column align-items-end">
									<div class="xt-search-input__paginate mt-1 mt-md-0">
										<b-pagination
											v-model="page"
											:total-rows="total_data"
											:per-page="perpage"
											first-number
											last-number
											prev-class="prev-item"
											next-class="next-item"
										>
											<template #prev-text>
												<feather-icon icon="ChevronLeftIcon" size="18" />
											</template>
											<template #next-text>
												<feather-icon icon="ChevronRightIcon" size="18" />
											</template>
										</b-pagination>
									</div>
									<div class="xt-search-input__search w-100">
										<div
											class="d-flex flex-column flex-md-row align-items-end justify-content-md-end"
										>
											<b-form-input
												type="search"
												class="search-input"
												@keyup.enter="initSearch"
												v-model="search"
												placeholder="Buscar placa"
												style="width: 200px"
											/>
											<div class="search-btn ml-0 ml-md-1 mt-1 mt-md-0">
												<button @click="initSearch" class="btn btn-primary w-100">
													Buscar
												</button>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</div>

					<div class="shadow corral-container">
						<div class="item" v-for="(item, key) in data" :key="key">
							<div class="icon">
								<FeatherIcon
									@click="!disabledSave && deletePlate(item)"
									icon="Trash2Icon"
									size="20"
									class=""
									:class="[!disabledSave ? 'text-danger' : 'text-secondary']"
								/>
							</div>
							<p>{{ item }}</p>
						</div>
						<div class="text-center w-100 font-weight-bolder" v-if="data.length == 0">
							<p class="mb-0 text-secondary">
								Sin {{ module == 1 ? "ejemplares" : "cintillos" }} por mostrar
							</p>
						</div>
					</div>
				</div>

				<!-- <div class="d-flex flex-column align-items-start w-100">
					<div class="d-flex flex-column flex-md-row align-items-start"></div>
					<div class="d-flex align-items-start w-100">
					</div>
				</div> -->
			</div>
		</validation-observer>

		<b-modal centered size="" hide-footer v-model="openModalAddPlate.show" :title="openModalAddPlate.title">
			<div class="container-valid-plate">
				<div v-for="(specimen, index) in openModalAddPlate.data" class="container-valid-plate__item">
					<div class="skeletor-specimen-add">
						<div @click="setPlate(specimen.plate)" class="skeletor"></div>
						<SpecimenPlate
							:specimen="{
								id: specimen.id,
								plate: specimen.plate,
								alias: specimen.alias,
								thumb: specimen.thumb,
								url: specimen.thumb,
								category: specimen.origin,
								additional_plate: specimen.additional_plate
							}"
						/>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import inventoryService from "@/services/inventory.service";
import moment from "moment";
import { mapMutations, mapState, mapActions } from "vuex";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	name: "CreateInventorySpecimens",
	components: {
		SpecimenPlate,
	},
	props: {
		module: {
			type: Number,
			default: 1,
		},
		inventory_id: {
			type: Number,
			default: null,
		},
		aliasAux: {
			type: String,
			default: null,
		},
		commentAux: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			plate: null,
			data: [],
			dataPage: [],
			total: 1,
			page: 1,
			perpage: 100,
			lastPage: 1,
			start_page: 1,
			to_page: 1,
			total_data: 1,
			search: null,
			updatedByUser: false,
			alias: this.aliasAux,
			comment: this.commentAux,
			openModalAddPlate: {
				show: false,
				title: "Seleccione un ejemplar",
				data: [],
			},
		};
	},
	computed: {
		...mapState("inventorySpecimens", ["inventory"]),
		disabledSave() {
			return this.inventory && [1, 2].includes(this.inventory.status_id0) && this.inventory.status != null;
		},
	},
	mounted() {
		// this.initLocalStorage(this.module);
		// this.refreshLocalStorage(this.data, this.module)
		// console.log('init', this.perpage * (this.page - 1) , this.perpage * ( this.page - 1 ) + this.perpage);
		this.initPaginate(this.inventory.value);

		if (!this.inventory.start_date) this.inventory.start_date = new Date();
	},
	methods: {
		...mapMutations("inventorySpecimens", ["SELECT_INVENTORY", "TOGGLE_RELOAD"]),
		...mapActions("inventorySpecimens", [
			"getInventories",
			"storeInventory",
			"updateInventory",
			"getCounterInventory",
			"getDetails",
		]),
		setPlate(plate) {
			if (!plate) {
				return this.showToast(
					"warning",
					"top-right",
					"Inventario",
					"InfoIcon",
					"No puede ingresar una placa en blanco"
				);
			}
			let plateExist = this.inventory.value.includes(plate);

			if (!plateExist) {
				const data = this.inventory.value;
				data.unshift(plate);
				this.SELECT_INVENTORY({
					item: {
						...this.inventory,
						value: data,
					},
				});
				this.plate = null;
				this.refreshLocalStorage(data, this.module);
				this.initPaginate(this.inventory.value);
				this.updatedByUser = true;
				this.showToast(
					"success",
					"top-right",
					"Inventario",
					"SuccessIcon",
					"Placa Agregado de forma correcta."
				);

				this.openModalAddPlate.data = [];
				this.openModalAddPlate.show = false;
			} else {
				this.showToast("warning", "top-right", "Inventario", "InfoIcon", "La placa ya fue ingresada");
			}
		},
		refreshLocalStorage(data, module) {
			localStorage.removeItem(`inventario-${module}`);
			localStorage.setItem(`inventario-${module}`, JSON.stringify(data));
		},
		initLocalStorage(module) {
			this.data = JSON.parse(localStorage.getItem(`inventario-${module}`)) || [];
			this.total = this.data.length;
			this.lastPage = this.total / this.perpage;
		},
		async deletePlate(plate) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.data = this.inventory.value.filter((it) => it != plate);
			this.SELECT_INVENTORY({
				item: {
					...this.inventory,
					value: this.data,
				},
			});
			this.initPaginate(this.data);
			this.updatedByUser = true;
		},
		async createInventory() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			await this.storeInventory({
				module: this.$route.meta.module,
				value: this.inventory.value,
				start_date: moment(this.inventory.start_date).format("YYYY-MM-DD"),
				parent_id: this.inventory_id,
				alias: this.alias,
				comment: this.comment,
			});

			this.$emit("refresh");
			this.isPreloading(false);
		},
		async updateInventoryAndAddPlates() {
			// console.log("update");
			// await this.updateInventory({});

			const { data, message, status } = await inventoryService.update({
				id: this.inventory.id,
				value: this.inventory.value,
				start_date: moment(this.inventory.start_date).format("YYYY-MM-DD"),
				comment: this.comment,
				alias: this.alias,
			});

			if (status) {
				this.showToast("success", "top-right", "Inventario ejemplares", "SuccessIcon", message);
				this.updatedByUser = false;
				// await this.getInventories();
				this.$emit("refresh");
			}
			console.log("this.module", this.module);
			if (this.$route.params.inventory_id) {
				await this.getCounterInventory({
					id: this.$route.params.inventory_id,
					module: this.module,
					matchIndex: this.$route.query.match,
					alias: this.alias,
					commnet: this.comment,
				});

				this.TOGGLE_RELOAD({ key: true });

				// await this.getDetails({
				// 	id: this.$route.params.inventory_id,
				// 	module: this.module == "specimens" ? 1 : 2,
				// 	page: 1,
				// 	perpage: 50,
				// 	search: null,
				// 	type: parseInt(this.$route.query.match ) + 1,
				// });
			}
		},
		initPaginate(data) {
			const dataL = data.slice(this.perpage * (this.page - 1), this.perpage * (this.page - 1) + this.perpage);
			this.data = dataL;
			this.total_data = this.inventory.value.length;
		},
		initSearch() {
			let data = [];
			if (this.search != null && this.search != "") {
				data = this.inventory.value.filter((it) => it == this.search);
			} else {
				data = this.inventory.value;
			}
			this.initPaginate(data);
			this.total_data = data.length;
		},
		escapeRegExp(string) {
			this.plate = string.replace(/[^a-zA-Z0-9-]/g, "");
		},
		async validaPlateSpecimen(plate) {
			// console.log('SDD', plate);

			if (!plate)
				return this.showToast(
					"warning",
					"top-right",
					"Inventario",
					"WarningIcon",
					"No se puede agregar una placa en blanco."
				);

			this.isPreloading();
			const data = await inventoryService.searchPlateOriginal({ plate });

			this.openModalAddPlate.data = data;

			// console.log('data', data);
			// this.setPlate(plate);

			this.isPreloading(false);

			if(data.length == 0) {
				return this.setPlate(plate);
			}

			if (data.length == 1) {
				this.setPlate(data[0].plate);
			} else {
				this.openModalAddPlate.show = true;
			}
		},
	},
	watch: {
		page() {
			this.data = this.inventory.value.slice(
				this.perpage * (this.page - 1),
				this.perpage * (this.page - 1) + this.perpage
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.corral-container {
	background: #f5f5f59f;
	display: flex;
	flex-wrap: wrap;
	padding: 0.5rem;

	@media screen and (min-width: 600px) {
		padding: 1rem;
	}
	@media screen and (min-width: 1000px) {
		padding: 2rem;
	}
	.item {
		background: #958fd2;
		// margin-right: 1rem;

		// padding: 0.23rem;
		margin-bottom: 0.5rem;
		border-radius: 20px;
		height: 40px;
		transform: scale(0.8);
		@media screen and (min-width: 600px) {
			margin-bottom: 1rem;
		}
		// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		// position: relative;
		p {
			padding: 0 1.2rem;
			margin-bottom: 0 !important;
			color: #fff;
			font-weight: bold;
			margin-right: 5px;
		}
		display: flex;
		align-items: center;
		transition: 0.5s all ease-in-out;
		.icon {
			margin-left: 5px;
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			// position: absolute;
			// top: -6px;
			// right: -6px;
			cursor: pointer;
			background: #fff;
			border-radius: 50%;
		}
		&:hover {
			box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
			// background: rgb(36, 36, 36);
		}
	}
}
.container-comment {
	width: 100%;
}
.container-btn-save {
	// background: red;
	// position: absolute;
	// top: 12px;
	// right: 12px;
	display: flex;
	justify-content: flex-end;
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		// margin-left: .2rem;
	}
	@media screen and (min-width: 900px) {
		// top: 18px;
		// right: 18px;
	}
}
.container-form {
	// background: #958fd2;
	padding: 1rem;
	margin-bottom: 2rem;
	border: 1.5px solid #c7c7c7;
	position: relative;
	// margin-top: 1rem;
	border-radius: 8px;
	padding-top: 1.5rem;
	margin-top: 1.5rem;
	h3 {
		position: absolute;
		top: -20px;
		left: 15px;
		background: #fff;
		padding: 0.5rem 1.5rem;
		margin-bottom: 0;
	}
}
.input-alias-add {
	width: 100%;
	@media screen and (min-width: 900px) {
		width: 15%;
	}
}

.container-valid-plate {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	@media screen and (min-width: 800px) {
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
	&__item {
		// background: red;
		padding: 0.5rem 0;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		// border: 1px solid #f5f5f5;
		// padding: 1rem;
		border-radius: 12px;
		overflow: hidden;
		// margin-bottom: 1rem;
		cursor: pointer;
		transition: 0.3s all ease-in-out;
		&:hover {
			background: #f5f5f5;
		}

		h3 {
			background: #7367f0;
			color: #fff;
			padding: 0.5rem;
		}
		.skeletor-specimen-add {
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.skeletor {
				position: absolute;
				width: 100%;
				height: 100%;
				background: transparent;
			}
		}
	}
}
</style>
