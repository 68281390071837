<template>
	<div>
		<!-- <div class="card flex-row p-2 d-flex align-items-center justify-content-between">
			<h2 class="mb-0">Codigo: {{ inventory.code }}</h2>
		</div> -->
		<!-- {{tabIndex}} -->
		<!-- {{$route.name}} -->
		<!-- {{ indexInventory }} -->

		<div class="container-items-nav card flex-column flex-md-row align-items-center justify-content-between">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 p-2" role="tabslits">
				<b-nav-item
					v-for="(tab, index) in counters"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:key="`${index}-index`"
					:active="indexInventory == index"
					@click="selectMatch(index)"
					:hidden="!tab.visible"
					:disabled="tab.counter == 0"
				>
					<span>{{ tab.name }}</span>
					<b-badge v-if="tab.counter > 0" pill variant="danger" class="ml-1">
						{{ tab.counter }}
					</b-badge>
				</b-nav-item>
			</ul>
			<div class="container-btn">
				<button @click="setInventoryMainData" class="btn btn-primary">Agregar</button>
			</div>
		</div>

		<b-tabs
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center"
			:value="indexInventory"
			lazy
		>
			<!-- counters[0].counter > 0 -->
			<!-- <b-tab>
				<TableSpecimensMatch v-if="true" :inventory_id="inventory_id" />
			</b-tab> -->
			<b-tab>
				<TableSpecimensUnMatch v-if="module == 1 && true" :inventory_id="inventory_id" />
				<TableChicksUnMatchVue v-if="module == 2 && true" :inventory_id="inventory_id" />
			</b-tab>

			<b-tab>
				<TableSpecimensMissing v-if="module == 1 && true" :inventory_id="inventory_id" />
				<TableChicksMissing v-if="module == 2 && true" :inventory_id="inventory_id" />
			</b-tab>

			<b-tab>
				<TableSpecimensSpare v-if="module == 1 && true" :inventory_id="inventory_id" />
				<TableChicksSpare v-if="module == 2 && true" :inventory_id="inventory_id" />
			</b-tab>
		</b-tabs>

		<b-sidebar
			v-model="sidebarCrearInventario.show"
			:title="sidebarCrearInventario.title"
			left
			width="100%"
			sidebar-class="sidebar-class"
			body-class="p-0 bg-white"
			scrollable
			@hidden="hideModal"
		>
			<CreateInventorySpecimens
				@refresh="refresh"
				v-if="sidebarCrearInventario.show"
				:data="[]"
				:module="module"
			/>
		</b-sidebar>
	</div>
</template>

<script>
import TableSpecimensMatch from "../components/TableSpecimensMatch.vue";
import TableSpecimensUnMatch from "../components/TableSpecimensUnMatch.vue";
import { mapState, mapActions, mapMutations } from "vuex";
// import TableChicksUnMatch from "../../chicks/components/TableChicksUnMatch.vue";
// import TableSpecimensUnMatch from "../components/TableSpecimensUnMatch.vue";
import TableSpecimensSpare from "../components/TableSpecimensSpare.vue";
import TableSpecimensMissing from "../components/TableSpecimensMissing.vue";
import TableChicksUnMatchVue from "../../chicks/components/TableChicksUnMatch.vue";
import TableChicksSpare from "../../chicks/components/TableChicksSpare.vue";
import TableChicksMissing from "../../chicks/components/TableChicksMissing.vue";
import CreateInventorySpecimens from "../components/CreateInventorySpecimens.vue";

export default {
	name: "ViewInventorySpecimensById",
	components: {
		TableSpecimensMatch,
		TableSpecimensUnMatch,
		TableSpecimensSpare,
		TableSpecimensMissing,
		TableChicksUnMatchVue,
		TableChicksSpare,
		TableChicksMissing,
		CreateInventorySpecimens,
	},
	data() {
		return {
			tabIndex: parseInt(this.$route.query.match),
			inventory_id: parseInt(this.$route.params.inventory_id),
			sidebarCrearInventario: {
				show: false,
				title: null,
				data: [],
			},
		};
	},
	async mounted() {
		this.$store.commit("app/M_IS_PRELOADING", true);
		this.isPreloading();
		await this.getCounterInventory({
			id: this.$route.params.inventory_id,
			module: this.module,
			is_change: true,
			matchIndex: parseInt(this.$route.query.match),
		});
	},
	computed: {
		...mapState("inventorySpecimens", ["inventory", "counters", "indexInventory"]),
		module() {
			return this.$route.meta.module;
		},
	},
	methods: {
		...mapActions("inventorySpecimens", ["getCounterInventory"]),
		...mapMutations("inventorySpecimens", ["UPDATE_INDEX_INVENTORY", "SET_FILTERS", "UPDATE_INDEX_TAB"]),
		selectMatch(tab) {
			this.UPDATE_INDEX_INVENTORY({ index: tab });
			this.SET_FILTERS({
				search: null,
				page: 1,
			});
			// console.log("tab", tab, this.indexInventory);
			this.$router.push({
				query: {
					match: tab,
				},
			});
			if (tab != this.indexInventory) {
			}
		},
		async refresh() {
			this.sidebarCrearInventario.show = false;

			// await this.getCounterInventory({
			// 	id: this.$route.params.inventory_id,
			// 	module: this.module,
			// 	is_change: true,
			// 	matchIndex: parseInt(this.$route.query.match),
			// });
		},
		hideModal() {
			// this.SELECT_INVENTORY({
			// 	item: {
			// 		value: [],
			// 	},
			// });
		},
		setInventoryMainData() {
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `Inventario / ${this.inventory.code || this.inventory.alias}`
			console.log('inv', this.inventory);

		}
	},
	watch: {
		indexInventory() {
			// console.log('df', this.indexInventory);
			// this.$router.push({
			// 	query: {
			// 		match: this.indexInventory,
			// 	},
			// });
		},
	},
};
</script>
<style scoped lang="scss">
.container-items-nav {
	.nav-tabs {
		@media screen and (min-width: 770px) {
			flex-direction: row;
		}
		width: 100%;
		@media screen and (min-width: 770px) {
				width: auto;
			}
		.nav-item {
			width: 100%;
			@media screen and (min-width: 770px) {
				width: auto;
			}
		}
	}
	.container-btn {
		margin-bottom: 1rem;
		width: 100%;
		@media screen and (min-width: 770px) {
			width: auto;
			margin-bottom: 0;
		}

		padding: 0 1.5rem;
		button {
			width: 100%;
			@media screen and (min-width: 770px) {
				width: auto;
			}
		}
	}
}
</style>
