<template>
	<b-modal centered no-close-on-backdrop title="Linea del ejemplar" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<h4>
				Lineas
				<b-button class="btn-icon ml-25" size="sm" variant="flat-success" v-if="lines.length < 4">
					<feather-icon
						icon="PlusIcon"
						@click="lines.push({ breeder: null, breederName: null, percentage: null })"
					/>
				</b-button>
			</h4>
			<hr />
			<b-row class="mb-1">
				<b-col cols="7">
					<h6 class="text-center">Criador</h6>
				</b-col>
				<b-col cols="3">
					<h6 class="text-center">Porcentaje</h6>
				</b-col>
				<b-col cols="2"></b-col>
			</b-row>
			<ValidationObserver ref="form">
				<b-row class="mb-1" v-for="(line, i) in lines" :key="i">
					<b-col cols="7">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								:class="{ 'border-danger': errors[0] }"
								v-model="line.breeder"
								label="text"
								:clearable="false"
								:options="breederOpts"
								:reduce="(opt) => opt.value"
								:selectable="disableBreederOpt"
								@input="saveSelected($event, i)"
								@search="searchBreeders"
							/>
						</ValidationProvider>
					</b-col>
					<b-col cols="3">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								:class="{ 'border-danger rounded': errors[0] }"
								v-model="line.percentage"
								label="text"
								:clearable="false"
								:options="percentageOpts"
								:reduce="(opt) => opt.value"
							/>
						</ValidationProvider>
					</b-col>
					<b-col cols="2">
						<b-button class="btn-icon" variant="flat-danger" @click="removeLine(i)" v-if="lines.length > 1">
							<feather-icon icon="TrashIcon" />
						</b-button>
					</b-col>
				</b-row>
			</ValidationObserver>
			<b-row class="mb-1">
				<b-col cols="7"></b-col>
				<b-col cols="3">
					<h6 class="text-center">Total: {{ totalPercentage }}%</h6>
				</b-col>
				<b-col cols="2"></b-col>
			</b-row>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="save">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

import specimenService from "@/services/specimens.service"

export default {
	components: { VueApexCharts },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		lines: [{ breeder: null, breederName: null, percentage: null }],
		breederOpts: [],
		prevSelected: [],
		percentageOpts: [
			{ value: 25, text: "25%" },
			{ value: 50, text: "50%" },
			{ value: 75, text: "75%" },
			{ value: 100, text: "100%" },
		],
	}),
	computed: {
		totalPercentage() {
			return this.lines.reduce((pre, cur) => cur.percentage + pre, 0)
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.lines = [{ breeder: null, percentage: null }]
			this.breederOpts = []
			this.prevSelected = []
			this.$emit("closing")
		},
		disableBreederOpt(opt) {
			if (this.prevSelected.some((b) => b.value == opt.value)) return false
			return true
		},
		searchBreeders(search, loading) {
			loading(true)
			specimenService.getBreederOpts({ search: search }).then((res) => {
				let existingIds = this.prevSelected.map((b) => b.value)
				this.breederOpts = res.data
					.filter((b) => !existingIds.includes(b.id))
					.map((b) => ({
						value: b.id,
						text: b.participant_name,
					}))
				this.breederOpts = [...this.breederOpts, ...this.prevSelected]
				loading(false)
			})
		},
		saveSelected(opt, idx) {
			// guarda la opcion para deshabilitar y asigna el nombre
			if (this.prevSelected.some((b) => b.value == opt)) return
			let breeder = this.breederOpts.find((b) => b.value == opt)
			this.prevSelected.push(breeder)
			this.lines[idx].breederName = breeder.text
		},
		removeLine(idx) {
			// quitar el registro del historial seleccionados
			let line = this.lines[idx]
			let prevIndex = this.prevSelected.findIndex((b) => b.value == line.breeder)
			if (prevIndex != -1) this.prevSelected.splice(prevIndex, 1)
			// quitar linea
			this.lines.splice(idx, 1)
		},
		async getFirstBreeders() {
			this.isLoading = true
			let existingIds = this.prevSelected.map((b) => b.value)
			const { data } = await specimenService.getBreederOpts({ search: "" })
			this.breederOpts = data
				.filter((b) => !existingIds.includes(b.id))
				.map((p) => ({
					value: p.id,
					text: p.participant_name,
				}))
			this.breederOpts = [...this.breederOpts, ...this.prevSelected]
			this.isLoading = false
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			let totalPercentage = this.lines.reduce((pre, cur) => cur.percentage + pre, 0)
			if (totalPercentage != 100) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incorrectos",
					"AlertCircleIcon",
					"El porcentaje total tiene que ser 100%"
				)
				return
			}
			this.isLoading = true

			let payload = this.lines.map((l) => ({
				percentage: l.percentage,
				breeder_id: l.breeder,
				breederName: l.breederName,
			}))
			this.$emit("new-line", payload)
			this.isLoading = false
			this.handleHidden()
		},
		populateLines() {
			this.prevSelected = this.info.lines.map((l) => ({
				text: l.breederName,
				value: l.breeder_id,
			}))
			this.lines = this.info.lines.map((l) => ({
				breeder: l.breeder_id,
				breederName: l.breederName,
				percentage: l.percentage,
			}))
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				if (this.info.lines && this.info.lines.length > 0) this.populateLines()
				this.getFirstBreeders()
			}
		},
	},
}
</script>
