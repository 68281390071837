import axios from "@/axios"

const base = "/api/specimens/races"

class RaceService {
	// brain
	async getRaces({ status }) {
		const { data } = await axios.get(`${base}/brain`, {
			params: { status },
		})
		return data
	}
	async insertRace({ name }) {
		const { data } = await axios.post(`${base}/brain`, { name })
		return data
	}
	async updateRace({ id, name, status }) {
		const { data } = await axios.put(`${base}/brain/${id}`, { name, status })
		return data
	}
	async deleteRace({ id }) {
		const { data } = await axios.delete(`${base}/brain/${id}`)
		return data
	}
	// specimen
	async getSpecimenRaces({ id }) {
		const { data } = await axios.get(`${base}/specimen/${id}`)
		return data
	}
	async updateSpecimenRaces({ specimen_id, races }) {
		const { data } = await axios.post(`${base}`, { specimen_id, races })
		return data
	}
}
export default new RaceService()
