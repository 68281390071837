<template>
	<div class="container-chicks" v-if="chick">
		<div class="header">
			<p class="mb-0" v-b-tooltip.hover title="Cintillo">{{ chick.cintillo }}</p>
			<b-form-checkbox class="" v-model="chick.check" v-if="check_disabled" :checked="chick.check">
			</b-form-checkbox>
		</div>
		<div class="body">
			<div class="details">
				<div class="details-item">
					<p class="mb-0">Genero:</p>
					<span v-b-tooltip.hover :title="chick.gender == 'SIN ASIGNAR' ? 'SIN ASIGNAR' : ''">{{
						chick.gender == "SIN ASIGNAR" ? "S/A" : chick.gender
					}}</span>
				</div>
				<div class="details-item">
					<p class="mb-0">Estado:</p>
					<span>{{ chick.status }}</span>
				</div>

				<div class="text-center text-md-left" v-if="[3, 4].includes(chick.status_id)">
					<b-button
						v-b-tooltip.hover
						title="Revivir"
						variant="outline-primary"
						@click="$emit('resurrect', chick)"
						><FeatherIcon icon="ActivityIcon" size="15" class="text-primary cursor-pointer" />
						Revivir</b-button
					>
				</div>
				<div v-else class="text-center text-md-left">
					<b-button
						v-b-tooltip.hover
						title="Matar / Depurar"
						variant="outline-danger"
						@click="$emit('dead', chick)"
						v-if="!visible_resurect"
					>
						<font-awesome-icon icon="fa-solid fa-skull" size="lg" />
						M/D</b-button
					>
				</div>
			</div>
			<div class="parents">
				<!-- {{}} -->
				<div class="parents-item" v-if="chick.madrilla">
					<p class="title mb-0">Madrilla</p>
					<img
						onerror="this.src='/rooster/rooster.jpg'"
						class="image"
						:src="chick.madrilla.thumb ? chick.madrilla.thumb : '/rooster/rooster.jpg'"
						alt=""
					/>
					<p class="alias mb-0" v-text="chick.madrilla.alias != null ? chick.madrilla.alias : '- -'"></p>
					<span class="plate mb-0">{{ chick.madrilla.plate }}</span>
				</div>

				<div class="parents-item" v-if="chick.padrillo">
					<p class="title mb-0">Padrillo</p>
					<img
						onerror="this.src='/rooster/rooster.jpg'"
						class="image"
						:src="chick.padrillo.thumb ? chick.padrillo.thumb : '/rooster/rooster.jpg'"
						alt=""
					/>
					<p class="alias mb-0" v-text="chick.padrillo.alias != null ? chick.padrillo.alias : '- -'"></p>
					<span class="plate mb-0">{{ chick.padrillo.plate }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	name: "CardChickInventory",
	props: {
		chick: {
			type: Object,
			default: () => {},
		},
		check_disabled: {
			type: Boolean,
			default: false,
		},
		index: {
			type: Number,
		},
		visible_resurect: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		...mapMutations("inventorySpecimens", ["TOGGLE_CHECK_UNMATCH"]),
	},
};
</script>

<style lang="scss" scoped>
.container-chicks {
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #f3f2f7;
		padding: 0.5rem;
		@media screen and (min-width: 700px) {
			padding: 1rem;
		}
		@media screen and (min-width: 1400px) {
			padding: 1.5rem;
		}
		p {
			font-weight: bolder;
			font-size: 16px;
		}
		border-left: 2px solid #d8d7da;
		border-right: 2px solid #d8d7da;
		border-top: 2px solid #d8d7da;
		border-radius: 8px 8px 0 0;
	}

	.body {
		border: 2px solid #d8d7da;
		// padding: 1.5rem;
		display: grid;
		grid-template-columns: 1fr;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		@media screen and (min-width: 1400px) {
			grid-template-columns: 2fr 3fr;
		}
		.details {
			padding: 0.5rem;
			@media screen and (min-width: 700px) {
				padding: 1rem;
			}
			@media screen and (min-width: 1400px) {
				padding: 1.5rem;
			}
			.details-item {
				margin: 1rem 0;
				display: flex;
				p {
					width: 100px;
					font-weight: bolder;
				}
				span {
				}
			}
		}
		.parents {
			padding: 0.5rem;
			@media screen and (min-width: 700px) {
				padding: 1rem;
				// border-left: 1.8px solid #cccccc;
			}
			@media screen and (min-width: 1400px) {
				padding: 1.5rem;
				border-left: 1.8px solid #cccccc;
			}

			display: grid;
			grid-template-columns: 1fr 1fr;
			.parents-item {
				// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
				text-align: center;
				.title {
					color: #6d6d6d;
					font-weight: 600;
				}
				.image {
					border-radius: 8px;
					height: 80px;
					width: 80px;
				}
				.alias {
					color: #6d6d6d;
					font-weight: 500;
					margin: 0.2rem 0 !important;
				}
				.plate {
					background: #7367ef;
					color: #fff;
					border: 12px;
					font-weight: bolder;
					// width: auto;
					padding: 0.2rem 1rem;
					border-radius: 12px;
				}
			}
		}
	}
}
</style>
