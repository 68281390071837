<template>
	<div class="w-100">
		<div v-if="isInventory" class="card mb-0 border-top-2 pb-50 pl-1">
			<ul class="nav nav-tabs align-items-center gap-x-1 mb-0 p-50" role="tabslits">
				<b-nav-item
					link-classes="px-1 py-25 justify-content-between"
					class="x-tab-width"
					:active="spec.tab == 0"
					@click="spec.tab = 0"
				>
					<span>Crear</span>
				</b-nav-item>
				<b-nav-item
					link-classes="px-1 py-25 justify-content-between"
					class="x-tab-width"
					:active="spec.tab == 1"
					@click="spec.tab = 1"
				>
					<span>Actualizar</span>
				</b-nav-item>
			</ul>
		</div>

		<b-tabs
			content-class="p-2 pr-4"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center"
			v-model="spec.tab"
			lazy
		>
			<b-tab class="content-tab">
				<b-row>
					<b-col cols="12" md="3" class="mb-1">
						<b-form-group :label-for="'origin-' + index" label="Origen">
							<ValidationProvider
								:rules="spec.check || !isInventory ? 'required' : ''"
								v-slot="{ errors }"
							>
								<v-select
									placeholder="Seleccionar "
									v-model="spec.origin_id"
									:options="arrayOrigins"
									:clearable="false"
									:id="'origin-' + index"
									label="name"
									:reduce="(option) => option.id"
									:class="{ 'border-danger rounded': errors[0] }"
									:disabled="isInventory ? !spec.check : false"
									@input=";(spec.color_padrillo_id = null), (spec.color_madrilla_id = null)"
								></v-select>
								<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3" class="mb-1">
						<b-form-group :label-for="'category-' + index" label="Categoría">
							<ValidationProvider
								:rules="spec.check || !isInventory ? 'required' : ''"
								v-slot="{ errors }"
							>
								<v-select
									placeholder="Seleccionar"
									v-model="spec.category_id"
									:options="arrayCategories"
									:clearable="false"
									:id="'category-' + index"
									label="description"
									:reduce="(option) => option.id"
									:class="{ 'border-danger rounded': errors[0] }"
									:disabled="isInventory ? !spec.check : false"
								></v-select>
								<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3" class="mb-1">
						<b-form-group :label-for="'plate-' + index" label="Placa">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-form-input
									:disabled="isInventory"
									v-model="spec.plate"
									:id="'plate-' + index"
									:state="errors[0] ? false : null"
									placeholder="Escribe una placa"
								/>
								<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3" class="mb-1" v-if="![1, null].includes(spec.origin_id)">
						<b-form-group :label="`Linea del ejemplar (` + spec.lines.length + `)`">
							<b-button
								class="mb-05"
								size="sm"
								variant="primary"
								@click="
									;(breederLineModal.show = !breederLineModal.show),
										(breederLineModal.info = { lines: spec.lines })
								"
							>
								<FeatherIcon class="mx-1 cursor-pointer" icon="Edit3Icon" size="18" />
							</b-button>
							<!--  -->
							<ValidationProvider rules="" v-slot="{ errors }">
								<input class="hidden" v-model="spec.lines" />
								<br />
								<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3" class="mb-1" v-if="![1, null].includes(spec.origin_id)">
						<b-form-group :label="`Raza del ejemplar (` + spec.races.length + `)`">
							<b-button
								class="mb-05"
								size="sm"
								variant="primary"
								@click="
									;(raceModal.show = !raceModal.show),
										(raceModal.info = { races: spec.races, creating: true })
								"
							>
								<FeatherIcon class="mx-1 cursor-pointer" icon="Edit3Icon" size="18" />
							</b-button>
							<!--  -->
							<ValidationProvider rules="" v-slot="{ errors }">
								<input class="hidden" v-model="spec.lines" />
								<br />
								<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3" class="mb-1" v-if="spec.origin_id != 1 && spec.origin_id != null">
						<b-form-group :label-for="'addi-plate-' + index" label="Placa adicional">
							<b-form-input
								v-model="spec.addi_plate"
								:id="'addi-plate-' + index"
								placeholder="Escribe una placa adicional"
								:disabled="isInventory ? !spec.check : false"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3" class="mb-1" v-if="spec.origin_id == 1 && spec.origin_id != null">
						<b-form-group :label-for="'dob-' + index" label="Nacimiento">
							<ValidationProvider
								:rules="spec.check || (!isInventory && spec.origin_id == 1) ? 'required' : ''"
								v-slot="{ errors }"
							>
								<flat-pickr
									:id="'dob' + index"
									v-model="spec.dob"
									placeholder="Elige una Fecha"
									class="form-control bg-transparent"
									:class="{ 'border-danger rounded': errors[0] }"
									:config="datepickerConf"
								/>
								<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<!-- <b-col cols="12" md="3" class="mb-1" v-if="spec.origin_id == 2">
						<b-form-group label="Color Padrillo">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									placeholder="Seleccionar"
									:class="{ 'border-danger rounded': errors[0] }"
									v-model="spec.color_padrillo_id"
									:options="maleColors"
									label="color"
									:reduce="(option) => option.id"
								/>
								<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3" class="mb-1" v-if="spec.origin_id == 2">
						<b-form-group label="Color Madrilla">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									placeholder="Seleccionar"
									:class="{ 'border-danger rounded': errors[0] }"
									v-model="spec.color_madrilla_id"
									:options="femaleColors"
									label="color"
									:reduce="(option) => option.id"
								/>
								<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col> -->
					<b-col cols="12" md="3" class="mb-1" v-if="spec.origin_id != 1 && spec.origin_id != null">
						<b-form-group :label-for="'color-' + index" label="Criador">
							<v-select
								placeholder="Seleccionar "
								v-model="spec.breeder_id"
								:options="S_BREEDERS"
								label="name"
								:reduce="(option) => option.id"
							></v-select>
						</b-form-group>
					</b-col>
					<template v-if="[1, 2].includes(spec.origin_id) && spec.origin_id != null">
						<b-col cols="12" md="3" class="mb-1">
							<b-form-group :label-for="'color-' + index" label="Padrillo">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<SelectSpecimens
										:specimen="spec"
										:valid_dob="spec.origin_id == 1"
										v-model="padrillo"
										categories="1"
										:class="{ 'border-danger rounded': errors[0] }"
										@selected="setSpecimen($event)"
									/>
									<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
						</b-col>
						<b-col cols="12" md="3" class="mb-1">
							<b-form-group :label-for="'color-' + index" label="Madrilla">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<SelectSpecimens
										:specimen="spec"
										:valid_dob="spec.origin_id == 1"
										v-model="madrilla"
										categories="2"
										:class="{ 'border-danger rounded': errors[0] }"
										@selected="setSpecimen($event, true)"
									/>
									<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
						</b-col>
					</template>
					<b-col cols="12" md="3" class="mb-1">
						<b-form-group label="Medidas del ejemplar">
							<b-button
								class="mr-1"
								size="sm"
								variant="primary"
								@click="
									;(measureModal.show = !measureModal.show),
										(measureModal.info = { races: spec.measurements, creating: true })
								"
							>
								<FeatherIcon class="mx-1 cursor-pointer" icon="Edit3Icon" size="18" />
							</b-button>
							<span v-if="spec.measurements && spec.measurements.length != 0">
								{{ spec.measurements.length }} Medidas asignadas
							</span>
						</b-form-group>
					</b-col>
					<div
						class="position-absolute"
						v-if="arraySpecs.length - 1 == index && !isInventory"
						style="bottom: 12px; right: 12px; z-index: 100"
					>
						<b-button
							variant="gradient-primary"
							class="btn-icon rounded-circle"
							@click="$emit('addNewSpec')"
						>
							<feather-icon icon="PlusIcon" size="18" />
						</b-button>
					</div>
				</b-row>
			</b-tab>
			<b-tab :style="{ width: '100%' }">
				<UpdatePlateAndStatusSpecimen :spec="spec" :isInventory="isInventory" />
			</b-tab>
		</b-tabs>
		<AddBreederLineModal
			:show="breederLineModal.show"
			:info="breederLineModal.info"
			@closing="breederLineModal.show = false"
			@new-line="($event) => (spec.lines = $event)"
		/>
		<UpdateRaceModal
			:show="raceModal.show"
			:info="raceModal.info"
			@closing="raceModal.show = false"
			@new-line="($event) => (spec.races = $event)"
		/>
		<AddMeasures
			:show="measureModal.show"
			:info="measureModal.info"
			@closing="measureModal.show = false"
			@new-measures="($event) => (spec.measurements = $event)"
		/>
	</div>
</template>

<script>
import UpdatePlateAndStatusSpecimen from "./UpdatePlateAndStatusSpecimen.vue"
import SelectSpecimens from "@/views/amg/specimens/components/SelectSpecimens.vue"
import { mapActions, mapState } from "vuex"
import AddBreederLineModal from "./components/AddBreederLineModal.vue"
import UpdateRaceModal from "./components/UpdateRaceModal.vue"
import AddMeasures from "./dashboard/modals/AddMeasures.vue"

export default {
	name: "TabUpdatePlateAndStatusSpecimen",
	components: {
		UpdatePlateAndStatusSpecimen,
		SelectSpecimens,
		AddBreederLineModal,
		UpdateRaceModal,
		AddMeasures,
	},
	props: {
		spec: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
		},
		arraySpecs: {
			type: Array,
			default: [],
		},
		arrayOrigins: {
			type: Array,
		},
		arrayCategories: {
			type: Array,
		},
		arrayColors: {
			type: Array,
		},
		isInventory: {
			type: Boolean,
		},
	},
	data() {
		return {
			padrillo: null,
			madrilla: null,
			datepickerConf: {
				maxDate: new Date(),
			},
			breederLineModal: { show: false, info: {} },
			raceModal: { show: false, info: {} },
			measureModal: { show: false, info: {} },
		}
	},
	computed: {
		...mapState("createEditSpecimen", ["S_BREEDERS"]),
		maleColors() {
			return this.arrayColors.filter((c) => c.gender_id == 1)
		},
		femaleColors() {
			return this.arrayColors.filter((c) => c.gender_id == 2)
		},
	},
	async mounted() {
		this.A_GET_BREEDERS()
	},
	methods: {
		...mapActions("statusSpecimens", [
			"getUsersByRole",
			"getReasons",
			"getClients",
			"getDiseases",
			"getAllStatusSpecimens",
			"A_SAVE_STATUS_MASSIVELY",
		]),
		...mapActions("createEditSpecimen", ["A_GET_BREEDERS"]),
		setSpecimen(val, isMadrilla = false) {
			if (!val) {
				if (isMadrilla) {
					this.madrilla = null
					this.spec.madrilla_id = null
				} else {
					this.padrillo = null
					this.spec.padrillo_id = null
				}
				return
			}
			if (isMadrilla) {
				this.madrilla = val.specimen_id
				this.spec.madrilla_id = val.specimen_id
			} else {
				this.padrillo = val.specimen_id
				this.spec.padrillo_id = val.specimen_id
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.border-top-2 {
	border-bottom: 2px solid #d8d7da !important;
	border-radius: 0px;
	padding-right: 2rem;
	margin-right: 1rem;
}
</style>
