import axios from "@/axios"

const base = "/api/measures"

class RaceService {
	// brain
	async getMeasures() {
		const { data } = await axios.get(`${base}`)
		return data
	}
	async insertMeasure({ name }) {
		const { data } = await axios.post(`${base}/store`, { name })
		return data
	}
	async updateMeasure({ id, name, status }) {
		const { data } = await axios.put(`${base}/update/${id}`, { name, status })
		return data
	}
	async deleteMeasure({ id }) {
		const { data } = await axios.delete(`${base}/destroy/${id}`)
		return data
	}
}
export default new RaceService()
