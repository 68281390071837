<template>
	<b-modal centered no-close-on-backdrop :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-table-simple sticky-header="60vh">
					<b-thead class="text-center text-nowrap">
						<b-tr>
							<b-th>Medida</b-th>
							<b-th>Valor (cm)</b-th>
							<b-th v-if="!isEditing">Accion</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(m, idx) in measures" :key="idx">
							<b-td>
								<ValidationProvider rules="required" v-slot="{ errors }" v-if="!isEditing">
									<v-select
										style="width: 10rem"
										:class="{ 'border-danger rounded': !!errors[0] }"
										label="text"
										:clearable="false"
										:reduce="(opt) => opt.value"
										:options="measureOpts"
										:selectable="checkSelectable"
										appendToBody
										:calculatePosition="positionDropdown"
										v-model="m.key"
									/>
								</ValidationProvider>
								<v-select
									style="width: 12rem"
									label="text"
									disabled
									:clearable="false"
									:reduce="(opt) => opt.value"
									:options="measureOpts"
									v-model="m.key"
									v-else
								/>
							</b-td>
							<b-td>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-form-input
										type="number"
										:class="{ 'border-danger ': !!errors[0] }"
										v-model="m.value"
									/>
								</ValidationProvider>
							</b-td>
							<b-td v-if="!isEditing">
								<b-button
									class="btn-icon"
									variant="danger"
									:disabled="measures.length <= 1"
									@click="removeMeasure(idx)"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</ValidationObserver>
			<b-button class="btn-icon float-right" variant="outline-success" @click="addMeasure" v-if="!isEditing">
				<feather-icon icon="PlusIcon" />
			</b-button>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="info" @click="save" v-if="isEditing" :disabled="isLoading">Guardar</b-button>
			<b-button variant="success" @click="save" v-else :disabled="isLoading || measures.length == 0">
				Guardar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import measureService from "@/services/measure.service"
import specimensService from "@/services/specimens.service"

export default {
	props: {
		show: Boolean,
		// {_a:string} para saber si se edita
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		measures: [{ key: null, value: null }],
		measureOpts: [],
	}),
	computed: {
		isEditing() {
			return this.info._a == "edit"
		},
		isCreating() {
			// cuando se usa el modal al crear ejemplar
			return this.info.creating
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.measures = [{ key: null, value: null }]
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await measureService.getMeasures()
			this.measureOpts = data.filter((m) => m.status_id == 1).map((m) => ({ value: m.id, text: m.name }))
			this.isLoading = false
		},
		async save() {
			if (!(await this.$refs["form"].validate())) return
			const { isConfirmed } = await this.showConfirmSwal({})
			if (!isConfirmed) return
			this.isLoading = true
			const measurements = this.measures.map((m) => ({
				specimen_id: this.isCreating ? null : this.info.specimen.id,
				measurement_id: m.key,
				value: m.value,
			}))
			if (this.isCreating) {
				this.$emit("new-measures", measurements)
			} else {
				await specimensService.insertMeasurements({ measurements })
			}

			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
			this.showToast(
				"success",
				"top-right",
				"Medidas",
				"CheckIcon",
				this.isEditing ? "Registro editado exitosamente" : "Registros guardados exitosamente"
			)
		},
		addMeasure() {
			const base = { key: null, value: null }
			this.measures.push(base)
		},
		removeMeasure(idx) {
			this.measures.splice(idx, 1)
		},
		checkSelectable(opt) {
			const alreadySelected = !this.measures.some((m) => m.key == opt.value)
			// const alreadyRegistered = !this.info.measures.some((m) => m.id == opt.value)
			// return alreadySelected && alreadyRegistered
			return alreadySelected
		},
		setFields() {
			const prev = this.info.target
			this.measureOpts = [{ value: prev.id, text: prev.name }]
			this.measures = [{ key: prev.id, value: prev.value }]
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				if (this.isEditing) {
					this.title = "Editar medida"
					this.setFields()
				} else {
					this.title = "Agregar medidas"
					this.getOpts()
				}
			}
		},
	},
}
</script>
