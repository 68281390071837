<template>
	<div class="item">
		<div class="header">
			<FeatherIcon @click="$emit('deletePlate', chick)" icon="TrashIcon" size="18" class="text-danger cursor-pointer" />
			<b-form-checkbox class="" :disabled="!chick.newChick" v-model="chick.check" :checked="chick.check"></b-form-checkbox>
		</div>
		<div class="update-name">
			<b-form-group label="Cintillo" class="w-50">
				<b-form-input disabled id="input-1" class="" v-model="chick.plate" m></b-form-input>
			</b-form-group>

			<b-form-group label="Cambiar por" class="ml-1 w-100">
				<v-select
					class="w-100"
					v-model="chick.newChick"
					:options="chicks"
					label="cintillo"
					:reduce="(option) => option"
					transition="cintillo"
					placeholder="Buscar cintillos"
					:clearable="false"
					@input="chick.check_status = true; chick.check = true;"
					@search="
						(search, loading) =>
							getSpecimens({
								search,
								loading,
							})
					"
				>
					<template slot="no-options"> Sin cintillos encontrados </template>

					<template slot="option" slot-scope="option">
						<div class="d-center">{{ option.cintillo }} - {{ option.status }}</div>
					</template>
					<template slot="selected-option" slot-scope="option">
						<div class="selected d-center">{{ option.cintillo }} - {{ option.status }}</div>
					</template>
				</v-select>
			</b-form-group>

			<!-- <p class="mb-0">{{ chick }}</p> -->
		</div>
		<div class="footer" v-if="chick.newChick && [3, 4].includes(chick.newChick.status_id)">
			<b-form-checkbox disabled v-model="chick.check_status" :checked="chick.check_status">
				Revivir
			</b-form-checkbox>
		</div>
	</div>
</template>

<script>
import inventoryService from "@/services/inventory.service";

export default {
	name: "UpdateCintilloToInventory",
	props: {
		chick: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			chicks: [],
			chick_id: null,
		};
	},
	methods: {
		async getSpecimens({ search, loading }) {
			loading(true);
			if (search.length > 1) {
				const { data, message } = await inventoryService.getEntity({
					module: 2,
					search,
					id: this.$route.params.inventory_id,
				});

				this.chicks = data;
			}
			loading(false);
		},
	},
};
</script>

<style lang="scss" scoped>
.item {
	border: 2px solid #d8d7da;
	// padding: 1rem;
	border-radius: 8px;

	.header {
		padding: 1rem;
		background: #f3f2f7;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.update-name {
		padding: 1rem;
		display: flex;
		align-items: center;
		padding-bottom: 0 !important;
	}
	.footer {
		padding: 1rem;
		padding-top: 0 !important;

		margin-left: 0 !important;
	}
}
</style>
