<template>
	<div>
		<div class="card p-2 position-relative">
			<b-row>
				<b-col
					cols="12"
					md="12"
					lg="4"
					class="d-flex align-items-center justify-content-start justify-content-sm-start"
				>
					<span class="text-muted">
						Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
					</span>
				</b-col>
				<!-- Pagination -->
				<b-col cols="12" md="12" lg="8" class="flex-nowrap">
					<div class="flex flex-column align-items-end">
						<div class="xt-search-input__paginate mt-1 mt-md-0">
							<b-pagination
								:value="page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								prev-class="prev-item"
								next-class="next-item"
								@input="
									(e) =>
										SET_FILTERS({
											search: search,
											page: e,
										})
								"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</div>
						<div class="xt-search-input__search w-100">
							<div class="d-flex flex-column flex-md-row align-items-end justify-content-md-end">
								<b-form-input
									type="search"
									class="search-input"
									@keyup.enter="init"
									v-model="searchInput"
									placeholder="Placa o Alias"
									style="width: 200px"
									@input="
										SET_FILTERS({
											search: searchInput,
											page: page,
										})
									"
								/>
								<div class="search-btn ml-0 ml-md-1 mt-1 mt-md-0">
									<button @click="init" class="btn btn-primary w-100">Buscar</button>
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>

		<div class="card p-2 flex-row">
			<button class="btn btn-primary" @click="updateCintillosToInventory">Actualizar</button>
		</div>

		<div class="shadow corral-container">
			<!-- <div v-for="(chick, index) in unmatch">
				<p class="mb-0">Placa: {{ chick.plate }}</p>
			</div> -->

			<UpdateCintilloToInventory
				v-for="(item, key) in chicks"
				:key="key"
				:chick="item"
				@deletePlate="deletePlate"
			/>
		</div>
	</div>
</template>

<script>
import inventoryService from "@/services/inventory.service";
import { mapState, mapActions, mapMutations } from "vuex";
import UpdateCintilloToInventory from "./UpdateCintilloToInventory.vue";
import encasteChicksService from "@/services/encaste/chicks.service";

export default {
	name: "TableChicksMissing",
	components: {
		UpdateCintilloToInventory,
	},
	computed: {
		...mapState("inventorySpecimens", [
			"page",
			"perpage",
			"to_page",
			"total_data",
			"start_page",
			"search",
			"missing",
			"reload"
		]),
		module() {
			return this.$route.meta.module;
		},
	},
	props: {
		inventory_id: {
			type: Number,
		},
	},
	data() {
		return {
			searchInput: null,
			chicks: [],
		};
	},
	async mounted() {
		await this.init();
	},

	methods: {
		...mapMutations("inventorySpecimens", ["SET_FILTERS","TOGGLE_RELOAD","CLEAN_DATA"]),
		...mapActions("inventorySpecimens", ["getDetails", "removePlateOrCintillo", "getCounterInventory"]),
		async init() {
			this.CLEAN_DATA();
			this.isPreloading();
			await this.getDetails({
				id: this.inventory_id,
				module: this.module,
				page: this.page,
				perpage: this.perpage,
				search: this.search,
				type: 3,
			});
			this.chicks = this.missing.map((it) => ({ plate: it, check: false, check_status: false, newChick: null }));
			this.isPreloading(false);
		},
		async deletePlate(chick) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;

			this.isPreloading();
			await this.removePlateOrCintillo({
				id: this.$route.params.inventory_id,
				value: chick.plate,
			});
			await this.init();
			await this.getCounterInventory({
				id: this.$route.params.inventory_id,
				module: this.module,
				matchIndex: this.$route.query.match,
			});

			this.isPreloading(false);
		},
		async updateCintillosToInventory() {
			if (this.chicks.filter((it) => it.check).length == 0) {
				return this.showToast(
					"warning",
					"top-right",
					"Inventario pollos",
					"WarningIcon",
					"Debe de haber al menos un marcado."
				);
			}
			const cks = this.chicks.filter((it) => it.check);
			console.log("cks", cks);
			this.isPreloading();
			const res = await inventoryService.updateValue({
				add: cks.map((it) => it.newChick.cintillo),
				deleted: cks.map((it) => it.plate),
				id: this.inventory_id,
			});

			const resp = await encasteChicksService.toResurrectMassively({
				chicks: cks.map((it) => ({ id: it.newChick.id })),
			});

			await this.init();
			await this.getCounterInventory({
				id: this.$route.params.inventory_id,
				module: this.module,
				matchIndex: this.$route.query.match,
			});
			this.isPreloading(false);
		},
	},
	watch: {
		async page() {
			await this.init();
		},
		async reload() {
			if (this.reload) {
				await this.init();
				this.TOGGLE_RELOAD({ key: false });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.corral-container {
	background: #fff;
	display: flex;
	flex-wrap: wrap;
	padding: 2rem;
	border-radius: 12px;
	margin-top: 2rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 2rem;
}
</style>
