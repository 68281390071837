<template>
	<div>
		<b-form-group
			label="Responsable"
			:label-for="`responsable-${specimen.specimen_id}`"
			v-if="[6, 9, 10, 12, 13].includes(specimen.new_status_id)"
		>
			<ValidationProvider
				:rules="specimen.new_status_id == 10 && (!isInventory || specimen.check) ? 'required' : ''"
				v-slot="{ errors }"
			>
				<b-form-select
					:id="`responsable-${specimen.specimen_id}`"
					v-model="specimen.responsable_id"
					:options="arrayUsers"
					value-field="id"
					text-field="name"
					:state="errors[0] ? false : null"
					:disabled="isInventory ? !specimen.check : false"
				></b-form-select>
			</ValidationProvider>
		</b-form-group>

		<!-- MUDA Y PREPARACION -->
		<b-form-group
			v-if="specimen.new_status_id == 9 || specimen.new_status_id == 10"
			label="Peso"
			:label-for="`weight-${specimen.specimen_id}`"
		>
			<ValidationProvider :rules="!isInventory || specimen.check ? 'required' : ''" v-slot="{ errors }">
				<b-input-group append="£" label-for="weight">
					<b-form-input
						:id="`weight-${specimen.specimen_id}`"
						v-model="specimen.weight"
						type="number"
						placeholder="0"
						:state="errors[0] ? false : null"
						@input="convertPounds(specimen)"
						:disabled="isInventory ? !specimen.check : false"
					></b-form-input>
				</b-input-group>
			</ValidationProvider>
		</b-form-group>

		<!-- VENDIDO O DONADO -->
		<template v-if="[2, 4, 5, 11].includes(specimen.new_status_id)">
			<ValidationProvider
				:rules="
					[2, 4, 5, 11].includes(specimen.new_status_id) && (!isInventory || specimen.check) ? 'required' : ''
				"
				v-slot="{ errors }"
			>
				<b-form-group label="Cliente o Beneficiaro" :label-for="`client-${specimen.specimen_id}`">
					<v-select
						:id="`client-${specimen.specimen_id}`"
						v-model="specimen.client_id"
						:clearable="false"
						label="name"
						:options="arrayClients"
						:reduce="(option) => option.id"
						:disabled="isInventory ? !specimen.check : false"
						:state="errors[0] ? false : null"
						:class="{ 'border-danger rounded': errors[0] }"
					/>
				</b-form-group>
			</ValidationProvider>

			<b-form-group label="Precio" label-for="price " v-if="[2].includes(specimen.new_status_id)">
				<ValidationProvider :rules="!isInventory || specimen.check ? 'required' : ''" v-slot="{ errors }">
					<b-form-input
						id="price"
						v-model="specimen.price"
						label="name"
						type="text"
						placeholder="0"
						@keyup="justNumbersToFront(specimen)"
						@blur="fixedCost(specimen)"
						:state="errors[0] ? false : null"
						:disabled="isInventory ? !specimen.check : false"
					/>
				</ValidationProvider>
			</b-form-group>
		</template>

		<!-- MUERTO -->
		<template v-if="specimen.new_is_reason == 1">
			<b-form-group id="input-group-1" label="Motivo" label-for="input-1">
				<ValidationProvider :rules="!isInventory || specimen.check ? 'required' : ''" v-slot="{ errors }">
					<v-select
						v-model="specimen.reason_death_id"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="specimen.reasons"
						:clearable="false"
						label="reason"
						:reduce="(option) => option.id"
						:class="{ 'border-danger rounded': errors[0] }"
						@input="
							() => {
								specimen.others_description = ''
							}
						"
						:disabled="isInventory ? !specimen.check : false"
					>
						<template v-slot:option="option">
							<div class="d-flex justify-content-between align-items-center">
								<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`">
									{{ option.reason }}
								</span>
								<span v-if="option.parent_id">Sub-motivo de {{ option.parent_reason }}</span>
							</div>
						</template>
					</v-select>
				</ValidationProvider>
			</b-form-group>
			<b-form-group
				label="Otros"
				label-for="others_description"
				v-if="specimen.reason_death_id === 19"
			></b-form-group>
			<b-form-group id="input-group-1" label="Evidencias" label-for="input-1">
				<drag-and-drop-images
					v-model="specimen.evidences"
					:filesArray="specimen.evidences"
					:source="`flyers-${specimen.specimen_id}`"
					:containerTitle="`Arrastra las evidencias aquí`"
					:containerDescription="`Selecciona uno`"
					containerPadding="10px"
				></drag-and-drop-images>
			</b-form-group>
		</template>

		<!-- ENFERMO -->
		<!-- <template v-if="specimen.new_status_id == 6">
			<b-form-group id="input-group-1" label="Enfermedad" label-for="input-1">
				<ValidationProvider :rules="!isInventory || specimen.check ? 'required' : ''" v-slot="{ errors }">
					<v-select
						v-model="specimen.disease_id"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="arrayDiseases"
						:clearable="false"
						label="name"
						:reduce="(option) => option.id"
						:class="{ 'border-danger rounded': errors[0] }"
						@input="
							() => {
								specimen.others_description = ''
							}
						"
						:disabled="isInventory ? !specimen.check : false"
					></v-select>
				</ValidationProvider>
			</b-form-group>
		</template> -->

		<b-form-group
			label="Otros"
			label-for="others_description"
			v-if="
				(specimen.reason_death_id === 19 || specimen.disease_id === 1) &&
				[6, 3].includes(specimen.new_status_id)
			"
		>
			<ValidationProvider :rules="!isInventory || specimen.check ? 'required' : ''" v-slot="{ errors }">
				<b-input-group label-for="others_description">
					<b-form-textarea
						id="others_description"
						v-model="specimen.others_description"
						type="text"
						placeholder="Ingrese otra razon"
						:state="errors[0] ? false : null"
						:disabled="isInventory ? !specimen.check : false"
					></b-form-textarea>
				</b-input-group>
			</ValidationProvider>
		</b-form-group>
		<b-form-group
			v-if="specimen.new_status_id !== 3 && specimen.disease_id !== 1"
			label="Descripción"
			:label-for="`observation-${specimen.specimen_id}`"
		>
			<b-form-textarea
				:id="`observation-${specimen.specimen_id}`"
				v-model="specimen.description"
				type="text"
				placeholder="Descripción"
				:disabled="isInventory ? !specimen.check : false"
			></b-form-textarea>
		</b-form-group>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue"
import vSelect from "vue-select"
import specimens from "@/store/modules/specimens"
export default {
	name: "DiferentsFieldStatus",
	components: { vSelect, DragAndDropImages },
	props: {
		specimen: {
			type: Object,
			required: true,
		},
		isInventory: {
			type: Boolean,
			default: false,
		},
	},
	created() {},
	mounted() {},
	data() {
		return {}
	},
	computed: {
		...mapState("statusSpecimens", ["arrayReasons", "arrayClients", "arrayDiseases", "arrayUsers"]),
		// isInventory() {
		// 	if ("inventory-specimens-by-id-sr" == this.$route.name) return false;
		// 	return true;
		// },
	},
	methods: {
		convertPounds() {
			this.specimen.pounds_weight = isNaN(this.specimen.weight) ? 0 : (this.specimen.weight * 2.20462).toFixed(3)
		},
		justNumbersToFront(specimen) {
			if (specimen.price) {
				specimen.price = this.justNumbers(specimen.price)
			}
		},
		fixedCost(specimen) {
			if (specimen.price && Number(specimen.price)) {
				specimen.price = this.fixedMoney(specimen.price)
			}
		},
	},
	watch: {},
}
</script>

<style></style>
