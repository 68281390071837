<template>
	<div>
		<div class="container-form" ref="container-form" :style="{ position: 'position' }">
			<template v-if="isInventory">
				<div v-if="arraySpecs.length != 0 && isInventory" class="card flex-row p-2 container-header-btns">
					<slot name="btn-process"></slot>
					<button class="btn btn-primary" @click="save">Procesar ejemplares</button>
				</div>
				<div v-else class="card text-center p-2">
					<p class="mb-0">Sin ejemplares por mostrar</p>
				</div>
			</template>

			<div class="grid-items">
				<ValidationObserver ref="form" v-if="arraySpecs.length != 0">
					<b-card class="p-0" body-class="p-0" v-for="(spec, index) in arraySpecs" :key="`spec-${index}`">
						<div class="container-create-specimen m-0">
							<div class="container-check">
								<div v-if="!isInventory">
									<b-badge variant="primary">
										{{ index + 1 }}
									</b-badge>
								</div>
								<b-form-checkbox
									v-else
									@change="
										$emit('changeCheckItemSpecimen', {
											index,
											value: !spec.check,
										})
									"
									class="text-center"
									:checked="spec.check"
								></b-form-checkbox>
							</div>
							<b-row class="w-100 container-fields">
								<TabUpdatePlateAndStatusSpecimen
									:spec="spec"
									:index="index"
									:arraySpecs="arraySpecs"
									:arrayOrigins="arrayOrigins"
									:arrayCategories="arrayCategories"
									:arrayColors="arrayColors"
									@addNewSpec="addNewSpec"
									:isInventory="isInventory"
								/>
							</b-row>
						</div>

						<feather-icon
							v-if="!isInventory"
							icon="XIcon"
							class="text-danger cursor-pointer"
							@click="deleteSpec(spec, index)"
							size="20"
							style="position: absolute; top: 10px; right: 10px"
						/>
						<feather-icon
							v-if="isInventory"
							icon="Trash2Icon"
							class="text-danger cursor-pointer"
							@click="$emit('delete-plate-specimen', spec)"
							size="20"
							style="position: absolute; bottom: 20px; right: 20px"
						/>
					</b-card>
				</ValidationObserver>
			</div>
		</div>
		<div class="text-right mt-1" v-if="!isInventory">
			<b-button :to="{ name: 'ejemplares-sr' }" variant="secondary" class="mr-1">Cancelar</b-button>
			<b-button variant="primary" @click="save()" v-if="!loading">Guardar</b-button>
			<b-button variant="primary" disabled v-else>
				<b-spinner small />
				Cargando...
			</b-button>
		</div>
	</div>
</template>

<script>
import { setEvidencesBase64 } from "@/helpers/functions"
import inventoryService from "@/services/inventory.service"
import specimensService from "@/services/specimens.service"
import moment from "moment"
import flatPickr from "vue-flatpickr-component"
import { mapState, mapMutations, mapActions } from "vuex"
import TabUpdatePlateAndStatusSpecimen from "./TabUpdatePlateAndStatusSpecimen.vue"

export default {
	name: "CreateManySpecimens",
	components: { flatPickr, TabUpdatePlateAndStatusSpecimen },
	props: {
		specimens: {
			type: Array,
			default: () => [],
		},
		isInventory: {
			type: Boolean,
			default: false,
		},
	},
	async mounted() {
		this.isPreloading()
		await Promise.all([this.getOrigins(), this.getCategories(), this.getColors()])
		await this.getAllStatusSpecimens({category_id:1})
		await this.getReasons(1)

		await this.getClients()

		await this.getUsersByRole()

		await this.getDiseases()
		this.arraySpecs =
			this.selectedSpecimensStatus.length > 0
				? this.selectedSpecimensStatus
				: [
						{
							origin_id: null,
							category_id: null,
							gender_id: null,
							plate: null,
							addi_plate: null,
							color_id: null,
							dob: null,
							description: null,
							date_status: null,
							client_id: null,
							disease_id: null,
							user_id: null,
							weight: null,
							pounds_weight: null,
							reason_id: null,
							evidences: [],
							customFormData: [],
							price: null,
							lines: [],
							color_padrillo_id: null,
							color_madrilla_id: null,
							races: [],
						},
				  ]

		if (this.specimens.length > 0 && this.isInventory) {
			this.arraySpecs = this.specimens
		}

		this.isPreloading(false)
	},
	computed: {
		...mapState("statusSpecimens", [
			"selectedSpecimensStatus",
			"arrayUsers",
			"arrayStatus",
			"arrayReasons",
			"arrayDiseases",
			"arrayClients",
		]),
		// isInventory() {
		// 	if ("inventory-specimens-by-id-sr" == this.$route.name) return true;
		// 	return false;
		// },
		...mapState("inventorySpecimens", [
			"page",
			"perpage",
			"to_page",
			"total_data",
			"start_page",
			"search",
			"match",
			"unmatch",
		]),
		arrayLEn() {
			return this.specimens.length
		},
		module() {
			return this.$route.meta.module
		},
		inventory_id() {
			return this.$route.params.inventory_id
		},
	},
	data() {
		return {
			arraySpecs: [
				{
					origin_id: null,
					category_id: null,
					gender_id: null,
					plate: null,
					addi_plate: null,
					color_id: null,
					dob: null,
					breeder_id: null,
					padrillo_id: null,
					madrilla_id: null,
					lines: [],
					color_padrillo_id: null,
					color_madrilla_id: null,
					races: [],
					measurements: [],
				},
			],
			arrayOrigins: [],
			arrayCategories: [],
			arrayColors: [],
			loading: false,
			arrayDeads: [
				{
					type: 3,
					id: null,
					observation: null,
					circumstances: null,
					evidences: [],
					customFormData: [],
					breeder_id: null, // criador
					others_description: null,
				},
			],
		}
	},
	methods: {
		...mapMutations("statusSpecimens", ["M_TOGGLE_CHECK_SPECIMEN"]),
		...mapActions("createEditSpecimen", [
			"A_GET_CATEGORIES_SPECIMENS",
			"A_GET_COLORS_SPECIMENS",
			"A_GET_ORIGINS_SPECIMENS",
			"A_GET_BREEDERS",
			"A_GET_TRACKING_FIELDS_SPECIMEN",
			"A_GET_SPECIMENS_BY_CATEGORY",
			"A_SAVE_SPECIMEN",
			"A_UPDATE_PRINCIPAL_PHOTO",
			"A_ADD_IMAGE_BY_ID",
		]),
		...mapActions("statusSpecimens", [
			"getUsersByRole",
			"getReasons",
			"getClients",
			"getDiseases",
			"getAllStatusSpecimens",
			"A_SAVE_STATUS_MASSIVELY",
		]),

		...mapActions("inventorySpecimens", ["getCounterInventory"]),
		addNewSpec() {
			let obj = {
				origin_id: null,
				category_id: null,
				plate: null,
				addi_plate: null,
				color_id: null,
				dob: null,
				lines: [],
				races: [],
			}
			this.arraySpecs.push(obj)
			this.scrollToBotom()
		},
		deleteSpec(spec, index) {
			if (this.arraySpecs.length <= 1) {
				this.$swal({
					title: "Error",
					text: "Debe haber al menos un ejemplar",
					icon: "error",
				})
				return
			}
			this.arraySpecs = this.arraySpecs.filter((it, key) => index !== key)
		},
		scrollToBotom() {
			let myRef = this.$refs["container-form"]
			myRef.style.scrollBehavior = "smooth"
			setTimeout(() => {
				myRef.scrollTop = myRef.scrollHeight - myRef.clientHeight
			}, 100)
		},
		async save() {
			if (!(await this.$refs.form.validate())) return

			if (this.isInventory && this.arraySpecs.filter((it) => it.check).length == 0) {
				return this.showToast(
					"info",
					"top-right",
					"Inventario ejemplares",
					"InfoIcon",
					"Sin ejemplares marcados"
				)
			}

			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()

			const dataCreate = this.arraySpecs.filter((it) => it.check && it.tab == 0)
			let dataUpdated = this.arraySpecs.filter((it) => it.check && it.tab == 1)
			if (dataCreate.length > 0 || !this.isInventory) {
				this.loading = true
				const { status, message, duplicates } = await specimensService.saveManySpecs({
					arraySpecs: !this.isInventory ? this.arraySpecs : dataCreate,
				})

				await inventoryService.updatePlateSpecimenByOrigin({
					id: this.inventory_id,
					specimens: dataCreate.map((it) => ({ plate: it.plate, origin_id: it.origin_id })),
				})

				if (!this.isInventory) {
					if (duplicates.length) {
						this.showToast("warning", "top-right", "Ejemplares existentes", "AlertIcon", "Placas Repetidas")
						this.arraySpecs = this.arraySpecs.filter((s) => {
							if (duplicates.includes(s.plate)) {
								return s
							}
						})
					} else {
						await this.$refs.form.reset()
						this.showSuccessToast(message)
						this.$router.push({ name: "ejemplares-sr" })
						this.arraySpecs = [
							{
								origin_id: null,
								category_id: null,
								gender_id: null,
								plate: null,
								addi_plate: null,
								color_id: null,
								dob: null,
								description: null,
								date_status: null,
								client_id: null,
								disease_id: null,
								user_id: null,
								weight: null,
								pounds_weight: null,
								reason_id: null,
								evidences: [],
								customFormData: [],
								price: null,
								lines: [],
								color_padrillo_id: null,
								color_madrilla_id: null,
								races: [],
							},
						]
					}
				}
			}

			if (this.isInventory) {
				if (dataUpdated.length > 0) {
					let deads = []

					dataUpdated.forEach(async (specimen) => {
						//VENDIDO
						specimen.specimen_id = specimen.newSpecimen.id
						specimen.reason_death_id = specimen.reason_id
						specimen.others_description = specimen.description

						// let dat = date_status.split('-');

						// specimen.date_status = moment(`${dat[2]}-${dat[1]}-${dat[0]}`).format('YYYY-MM-DD')

						specimen.date_status = moment(specimen.date_status).format("YYYY-MM-DD")
						if (specimen.new_status_id === 2) {
							specimen.cleanPrice = JSON.parse(JSON.stringify(specimen.price))
							specimen.cleanPrice = justNumbers(specimen.cleanPrice)
						}

						//DONADO, VENDIDO, REGALADO, PRESTADO
						if ([2, 4, 5, 11].includes(specimen.new_status_id)) {
							specimen.type_ouput =
								specimen.new_status_id === 2
									? 1
									: specimen.new_status_id === 4
									? 2
									: specimen.new_status_id === 5
									? 3
									: specimen.new_status_id === 11
									? 4
									: null
						}
					})
					if (dataUpdated.length > 0) {
						deads = await setEvidencesBase64(dataUpdated)
						deads = deads.map((d) => ({
							...d,
							id_specimen: d.id,
							id: d.plate,
							observation: d.resason || null,
							type: 3,
							others_description: d.other_description,
						}))
					}

					let body = {
						created_by: 1,
						all_status: dataUpdated.filter((it) => it.newSpecimen.status_id != 1),
						deads: JSON.stringify(deads.filter((it) => it.newSpecimen.status_id != 1)),
					}

					// Actualizar estado masivo
					await specimensService.saveStatusMassively(body)

					// Actualizar placas al inventario (quitar y agregar)
					const resp = await inventoryService.updateValue({
						id: this.inventory_id,
						add: dataUpdated.map((it) => it.newPlateSpecimen),
						deleted: dataUpdated.map((it) => it.plate),
					})
				}
				await this.getCounterInventory({
					id: this.$route.params.inventory_id,
					module: this.module,
					matchIndex: this.$route.query.match,
				})
				await this.$emit("refresh")
				this.arraySpecs = this.specimens
			}

			this.isPreloading(false)
			this.loading = false
		},

		selectNewSpecimen(e) {},
		async getOrigins() {
			const { data } = await specimensService.getOriginsSpecimen()
			this.arrayOrigins = [...data]
		},
		async getCategories() {
			const { data } = await specimensService.getCategoriesSpecimens()
			this.arrayCategories = data
		},
		async getColors() {
			const {
				colores: { data },
			} = await specimensService.getColors({
				page: 1,
				perpage: 1000,
				search: null,
			})
			this.arrayColors = data
		},
	},
	watch: {
		page() {
			this.arraySpecs = this.specimens
		},
		arrayLEn() {
			this.arraySpecs = this.specimens
		},
	},
}
</script>

<style lang="scss" scoped>
.container-form {
	// overflow: auto;
	// overflow-y: scroll;
	// overflow-x: hidden;
	// width: 90%;
	padding: 0 !important;
	// background: red;
}
.container-create-specimen {
	display: flex;
	align-items: center;
	// justify-content: c;
	.container-check {
		// background: red;
		width: 80px;
		display: flex;
		justify-content: center;
		height: 100% !important;
	}
	.container-fields {
		border-left: 2px solid #d8d7da;
		// padding: 1rem;
		// padding: 0 1rem;
	}
}
.container-header-btns {
	position: sticky;
	top: 0;
	z-index: 100;
}
.grid-items {
	// background: red;
	height: calc(90vh - 160px) !important;
	overflow-y: scroll;
	overflow-x: hidden;
}
</style>
