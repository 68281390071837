<template>
	<div>
		<div class="card p-2">
			<div class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-column align-items-end">
							<div class="xt-search-input__paginate mt-1 mt-md-0">
								<b-pagination
									:value="page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
									@input="
										(e) =>
											SET_FILTERS({
												search: search,
												page: e,
											})
									"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search w-100">
								<div class="d-flex flex-column flex-md-row align-items-end justify-content-md-end">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="searchInput"
										placeholder="Buscar por placa"
										style="width: 200px"
										@input="
											SET_FILTERS({
												search: searchInput,
												page: page,
											})
										"
									/>
									<div class="search-btn ml-0 ml-md-1 mt-1 mt-md-0">
										<button @click="init" class="btn btn-primary w-100">Buscar</button>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>

		<ValidationObserver tag="div" ref="form">
			<CreateManySpecimens
				@delete-plate-specimen="deletePlateSpecimen"
				:specimens="specimens"
				isInventory
				@changeCheckItemSpecimen="changeCheckItemSpecimen"
				@refresh="init"
			>
				<template v-slot:btn-process>
					<!-- <button class="btn btn-primary" @click="createSpecimens">Procesar</button> -->
				</template>
			</CreateManySpecimens>
		</ValidationObserver>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import CreateManySpecimens from "@/views/amg/specimens/CreateMany.vue";

export default {
	name: "TableSpecimensMissing",
	components: {
		CreateManySpecimens,
	},
	props: {
		inventory_id: {
			type: Number,
		},
	},
	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true },
				{ key: "fecha", title: "Fecha", visible: true },
				{ key: "plates", title: "Placas ingresadas", visible: true },
				{ key: "match", title: "#Coinciden", visible: true },
				{ key: "unmatch", title: "#No coinciden", visible: true },
				{ key: "status", title: "Estado", visible: true },
				{ key: "usuario", title: "Creado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			specimens: [],
			searchInput: null,
		};
	},
	computed: {
		...mapState("inventorySpecimens", [
			"page",
			"perpage",
			"to_page",
			"total_data",
			"start_page",
			"search",
			"match",
			"missing",
			"reload",
		]),
		module() {
			return this.$route.meta.module;
		},
	},
	async mounted() {
		await this.init();
	},
	methods: {
		...mapActions("inventorySpecimens", ["getDetails", "removePlateOrCintillo", "getCounterInventory"]),
		...mapMutations("inventorySpecimens", ["SET_FILTERS", "TOGGLE_RELOAD","CLEAN_DATA"]),
		async init() {
			this.specimens = [];
			this.CLEAN_DATA();
			await this.getDetails({
				id: this.inventory_id,
				module: this.module,
				page: this.page,
				perpage: this.perpage,
				search: this.search,
				type: 3,
			});
			this.specimens = this.missing.map((it) => {
				return {
					origin_id: null,
					category_id: null,
					gender_id: null,
					plate: it,
					addi_plate: null,
					color_id: null,
					dob: null,
					description: null,
					date_status: new Date(),
					client_id: null,
					disease_id: null,
					user_id: null,
					weight: null,
					pounds_weight: null,
					reason_id: null,
					evidences: [],
					customFormData: [],
					price: null,
					newSpecimen: null,
					check: false,
					tab: 0,
					newPlateSpecimen: null,
					customFormData: [],
				};
			});
			
		},
		async deletePlateSpecimen(specimen) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;

			this.isPreloading();
			const resp = await this.removePlateOrCintillo({
				id: this.inventory_id,
				value: specimen.plate,
			});
			await this.init();
			await this.getCounterInventory({
				id: this.$route.params.inventory_id,
				module: this.module,
				matchIndex: this.$route.query.match,
			});
			this.isPreloading(false);
		},
		changeCheckItemSpecimen({ index, value }) {
			this.specimens[index].check = value;
		},
	},
	watch: {
		async page() {
			await this.init();
		},
		async reload() {
			if (this.reload) {
				await this.init();
				this.TOGGLE_RELOAD({ key: false });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.search-input {
	width: 100% !important;
	@media screen and (min-width: 900px) {
		width: 200px !important;
	}
}
.search-btn {
	width: 100% !important;
	@media screen and (min-width: 900px) {
		width: 120px !important;
	}
}
.container-specimens-match {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;

	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(5, 1fr);
	}
}

.container-chicks-match {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;

	@media screen and (min-width: 500px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: 800px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and (min-width: 1000px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.card-specimen-inventory {
	background: #f5f5f5;
	border-radius: 12px;
}
</style>
