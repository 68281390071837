<template>
	<div>
		<div class="card p-2">
			<div class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-column align-items-end">
							<div class="xt-search-input__paginate mt-1 mt-md-0">
								<b-pagination
									:value="page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
									@input="
										(e) =>
											SET_FILTERS({
												search: search,
												page: e,
											})
									"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search w-100">
								<div class="d-flex flex-column flex-md-row align-items-end justify-content-md-end">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="searchInput"
										placeholder="Placa o Alias"
										style="width: 200px"
										@input="
											SET_FILTERS({
												search: searchInput,
												page: page,
											})
										"
									/>
									<div class="search-btn ml-0 ml-md-1 mt-1 mt-md-0">
										<button @click="init" class="btn btn-primary w-100">Buscar</button>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>

		<ValidationObserver tag="div" ref="form">
			<MainStates :in_galpon="0">
				<template v-slot:btn-process>
					<button class="btn btn-primary mr-1" @click="changeStatusSpecimens">Actualizar estados</button>
					<button class="btn btn-success" @click="specimensFound">Encontrados</button>
				</template>
			</MainStates>
		</ValidationObserver>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import MainStates from "@/views/amg/specimens/specimens-changes-status-massively/MainStatus.vue";

export default {
	name: "TableSpecimensSpare",
	components: {
		MainStates,
	},
	props: {
		inventory_id: {
			type: Number,
		},
	},
	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true },
				{ key: "fecha", title: "Fecha", visible: true },
				{ key: "plates", title: "Placas ingresadas", visible: true },
				{ key: "match", title: "#Coinciden", visible: true },
				{ key: "unmatch", title: "#No coinciden", visible: true },
				{ key: "status", title: "Estado", visible: true },
				{ key: "usuario", title: "Creado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			specimens: [],
			searchInput: null,
		};
	},
	computed: {
		...mapState("inventorySpecimens", [
			"page",
			"perpage",
			"to_page",
			"total_data",
			"start_page",
			"search",
			"match",
			"spare",
			"reload",
		]),
		...mapState("statusSpecimens", [
			"selectedSpecimensStatus",
			"arrayUsers",
			"arrayStatus",
			"arrayReasons",
			"arrayDiseases",
			"arrayClients",
		]),
		module() {
			return this.$route.meta.module;
		},
	},
	async mounted() {
		// this.CLEAN_SPECIMENS();
		await this.init();
	},
	methods: {
		...mapMutations("statusSpecimens", ["PUSH_FILTER_ARRAY_AVAILABLE_SPECIMENS", "M_INIT", "CLEAN_SPECIMENS"]),
		...mapActions("inventorySpecimens", [
			"getUnMatch",
			"getCounterInventory",
			"getDetails",
			"addOrRemoveValuesInventory",
		]),
		...mapMutations("inventorySpecimens", ["SET_FILTERS", "TOGGLE_RELOAD", "CLEAN_DATA"]),
		...mapActions("statusSpecimens", [
			"getUsersByRole",
			"getReasons",
			"getClients",
			"getDiseases",
			"getAllStatusSpecimens",
			"A_SAVE_STATUS_MASSIVELY",
			"A_SAVE_STATUS_MASSIVELY_CHECK",
		]),
		async init() {
			this.isPreloading();
			this.CLEAN_SPECIMENS();
			this.CLEAN_DATA();
			await this.getDetails({
				id: this.inventory_id,
				module: this.module,
				page: this.page,
				perpage: this.perpage,
				search: this.search,
				type: 2,
			});
			this.spare.forEach((it) => {
				this.PUSH_FILTER_ARRAY_AVAILABLE_SPECIMENS({
					alias: it.alias,
					category_description: null,
					category_id: null,
					check: false,
					client_id: null,
					customFormData: [],
					date_status: [],
					description: null,
					disease_id: null,
					dob: null,
					evidences: [],
					inside: null,
					new_status_id: null,
					observations: null,
					others_description: null,
					photo: it.url,
					plate: it.plate,
					pounds_weight: null,
					price: null,
					reason_death_id: null,
					responsable_id: null,
					specimen_id: it.id,
					status_description: it.status,
					status_id: it.status_id,
					thumb: it.thumb,
					type_ouput: null,
					value: null,
					weight: null,
					check: false,
				});
			});

			this.isPreloading(false);
		},
		async changeStatusSpecimens() {
			if (this.selectedSpecimensStatus.filter((it) => it.check).length == 0) {
				return this.showToast(
					"warning",
					"top-right",
					"Inventarios",
					"WarningIcon",
					"Debes marcar al menos un ejemplar para continuar."
				);
			}

			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campor por llenar"
				);
				return;
			}
			const specimensChange = this.selectedSpecimensStatus.filter((it) => it.check);
			// console.log("specimens", specimensChange);

			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro de actualizar los estados de ${specimensChange.length} ejemplares?`,
			});
			if (!confirm.value) return;
			this.isPreloading();
			try {
				await this.A_SAVE_STATUS_MASSIVELY_CHECK();

				await this.init();

				await this.getCounterInventory({
					id: this.$route.params.inventory_id,
					module: this.module,
					matchIndex: this.$route.query.match,
				});

				// this.$emit("hidden");
			} catch (error) {
				this.showErrorSwal(error);
			} finally {
				this.isPreloading(false);
			}
		},
		async specimensFound() {
			if (this.selectedSpecimensStatus.filter((it) => it.check).length == 0) {
				return this.showToast(
					"warning",
					"top-right",
					"Inventarios",
					"WarningIcon",
					"Debes marcar al menos un ejemplar para continuar."
				);
			}

			const specimensChange = this.selectedSpecimensStatus.filter((it) => it.check);
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro de agregar las placas de ${specimensChange.length} ejemplares al inventario?`,
			});
			if (!confirm.value) return;

			const { status, message } = await this.addOrRemoveValuesInventory({
				id: this.$route.params.inventory_id,
				add: specimensChange.map((it) => it.plate),
			});
			if (status) {
				await this.init();
				await this.getCounterInventory({
					id: this.$route.params.inventory_id,
					module: this.module,
					matchIndex: this.$route.query.match,
				});
				this.showToast("success", "top-right", "Inventario", "SuccessIcon", message);
			}
		},
	},
	watch: {
		async page() {
			await this.init();
		},
		async reload() {
			if (this.reload) {
				await this.init();
				this.TOGGLE_RELOAD({ key: false });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.search-input {
	width: 100% !important;
	@media screen and (min-width: 900px) {
		width: 200px !important;
	}
}
.search-btn {
	width: 100% !important;
	@media screen and (min-width: 900px) {
		width: 120px !important;
	}
}
.container-specimens-match {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;

	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(5, 1fr);
	}
}

.container-chicks-match {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;

	@media screen and (min-width: 500px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (min-width: 800px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and (min-width: 1000px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (min-width: 1400px) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.card-specimen-inventory {
	background: #f5f5f5;
	border-radius: 12px;
}
</style>
