import { render, staticRenderFns } from "./CreateMany.vue?vue&type=template&id=02bff484&scoped=true"
import script from "./CreateMany.vue?vue&type=script&lang=js"
export * from "./CreateMany.vue?vue&type=script&lang=js"
import style0 from "./CreateMany.vue?vue&type=style&index=0&id=02bff484&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02bff484",
  null
  
)

export default component.exports