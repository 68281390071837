<template>
	<b-modal centered no-close-on-backdrop title="Raza del ejemplar" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<h4>
				Razas
				<b-button class="btn-icon ml-25" size="sm" variant="flat-success" v-if="races.length < 4">
					<feather-icon
						icon="PlusIcon"
						@click="races.push({ id: null, name: null, percentage: null })"
						v-b-tooltip.hover.top="`Agregar raza al ejemplar`"
					/>
				</b-button>
				<b-button
					class="btn-icon ml-25 float-right d-flex align-items-center"
					size="sm"
					variant="info"
					v-b-tooltip.hover.top="`Crear nueva raza`"
					v-if="races.length < 4"
					@click="createRace.show = !createRace.show"
				>
					<feather-icon icon="PlusIcon"  />
					<!-- <span class="ml-25">Crear nueva raza</span> -->
				</b-button>
			</h4>
			<hr />
			<b-row class="mb-1">
				<b-col cols="7">
					<h6 class="text-center">Raza</h6>
				</b-col>
				<b-col cols="3">
					<h6 class="text-center">Porcentaje</h6>
				</b-col>
				<b-col cols="2"></b-col>
			</b-row>
			<ValidationObserver ref="form">
				<b-row class="mb-1" v-for="(race, i) in races" :key="i">
					<b-col cols="7">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								:class="{ 'border-danger': errors[0] }"
								v-model="race.id"
								label="text"
								:clearable="false"
								:options="raceOpts"
								:reduce="(opt) => opt.value"
								:selectable="disableRaceOpt"
							/>
						</ValidationProvider>
					</b-col>
					<b-col cols="3">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								:class="{ 'border-danger rounded': errors[0] }"
								v-model="race.percentage"
								label="text"
								:clearable="false"
								:options="percentageOpts"
								:reduce="(opt) => opt.value"
							/>
						</ValidationProvider>
					</b-col>
					<b-col cols="2">
						<b-button class="btn-icon" variant="flat-danger" @click="removeRace(i)" v-if="races.length > 1">
							<feather-icon icon="TrashIcon" />
						</b-button>
					</b-col>
				</b-row>
			</ValidationObserver>
			<b-row class="mb-1">
				<b-col cols="7"></b-col>
				<b-col cols="3">
					<h6 class="text-center">Total: {{ totalPercentage }}%</h6>
				</b-col>
				<b-col cols="2"></b-col>
			</b-row>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="save">Guardar</b-button>
		</template>
		<CreateRaceModal
			:show="createRace.show"
			:info="createRace.info"
			@closing="createRace.show = false"
			@refresh="getOpts"
		/>
	</b-modal>
</template>

<script>
import raceService from "@/services/race.service"

import VueApexCharts from "vue-apexcharts"
import CreateRaceModal from "@/views/brain/specimens/components/CreateRaceModal.vue"

export default {
	components: { VueApexCharts, CreateRaceModal },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		races: [{ id: null, name: null, percentage: null }],
		raceOpts: [],
		percentageOpts: [
			{ value: 25, text: "25%" },
			{ value: 50, text: "50%" },
			{ value: 75, text: "75%" },
			{ value: 100, text: "100%" },
		],
		createRace: { show: false, info: { _a: "create" } },
	}),
	computed: {
		totalPercentage() {
			return this.races.reduce((pre, cur) => cur.percentage + pre, 0)
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.races = [{ breeder: null, percentage: null }]
			this.raceOpts = []
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await raceService.getRaces({ status: 1 })
			this.raceOpts = data.map((o) => ({ text: o.name, value: o.id }))
			this.isLoading = false
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			let totalPercentage = this.races.reduce((pre, cur) => cur.percentage + pre, 0)
			if (totalPercentage != 100) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incorrectos",
					"AlertCircleIcon",
					"El porcentaje total tiene que ser 100%"
				)
				return
			}
			this.isLoading = true
			const races = this.races.map((r) => ({ race_specimen_id: r.id, percentage: r.percentage }))
			if (!!this.info.creating) this.$emit("new-line", races)
			else {
				await raceService.updateSpecimenRaces({ specimen_id: this.info.id, races })
				this.showToast(
					"success",
					"top-right",
					"Raza",
					"AlertCircleIcon",
					"Los registros enviados. Estos seran visibles despues de las 12:00 AM"
				)
			}
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		removeRace(idx) {
			this.races.splice(idx, 1)
		},
		disableRaceOpt(opt) {
			if (this.races.some((r) => r.id == opt.value)) return false
			return true
		},
		populate() {
			if (this.info.races && this.info.races.length == 0) return
			this.races = this.info.races.map((r) => ({
				id: r.race_specimen_id,
				name: null,
				percentage: r.percentage,
			}))
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getOpts()
				// creating cuando viene de creare specimen simple o masivo, updating cuando es del chart en ds especimen
				if (!!this.info.creating || !!this.info.updating) this.populate()
			}
		},
	},
}
</script>
