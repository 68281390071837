<template>
	<b-table-simple sticky-header="75vh" striped responsive bordered style="min-height: 50vh" class="table-change">
		<!-- :stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'" -->
		<b-thead head-variant="light">
			<b-tr class="text-center">
				<b-th v-if="isInventory" class="text-center check-container">
					<div class="check">
						<b-form-checkbox
							class="text-center pl-0 input"
							:checked="checkAll"
							@change="checkAllTotal"
						></b-form-checkbox>
					</div>
				</b-th>
				<b-th v-else class="p-1">#</b-th>
				<b-th class="p-1">Gallo</b-th>
				<b-th class="p-1">Estado Actual</b-th>
				<b-th class="p-1">Nuevo Estado</b-th>
				<b-th class="p-1">Campos</b-th>
				<b-th class="p-1">Fecha</b-th>
				<b-th v-if="!isInventory" class="p-1 text-center">
					<div v-b-tooltip.hover="'Eliminar todo'">
						<feather-icon
							icon="Trash2Icon"
							class="text-danger cursor-pointer"
							size="20"
							@click="CLEAN_SPECIMENS()"
						/>
					</div>
				</b-th>
			</b-tr>
		</b-thead>
		<b-tbody>
			<b-tr
				v-for="(specimen, index) in selectedSpecimensStatus"
				:key="`specimen-${index}`"
				class="p-50 text-center"
			>
				<b-th v-if="isInventory" class="text-center check-container">
					<div class="check">
						<b-form-checkbox
							@change="M_TOGGLE_CHECK_SPECIMEN({ check: !specimen.check, key: index })"
							class="text-center pl-0 input"
							:checked="specimen.check"
						></b-form-checkbox>
					</div>
				</b-th>

				<b-th v-else class="p-1 text-center">{{ index + 1 }}</b-th>

				<b-th class="p-1">
					<!-- {{ specimen.photo }} -->
					<SpecimenPlate
						:specimen="{
							id: specimen.specimen_id,
							plate: specimen.plate,
							alias: specimen.alias,
							thumb: specimen.thumb,
							image: specimen.photo,
						}"
					/>
				</b-th>

				<b-th class="p-1 text-center">
					<div class="d-flex align-items-center justify-content-center gap-x-1">
						<span>
							<template v-if="[0, 1].includes(specimen.in_galpon)">
								<feather-icon
									size="18"
									v-b-tooltip.hover.top="specimen.in_galpon === 1 ? 'Disponible' : 'No disponible'"
									:class="{
										'text-danger': specimen.in_galpon === 0,
										'text-success': specimen.in_galpon === 1,
									}"
									icon="CheckCircleIcon"
								></feather-icon>
							</template>
						</span>
						<span style="font-weight: lighter">
							({{ specimen.status_description || specimen.status_id }})
						</span>
					</div>
				</b-th>

				<b-th class="p-1">
					<SelectStatusRooster
						v-if="specimen.statuses.length > 0"
						:index="index"
						:is_header="is_header"
						:specimen="specimen"
						:in_galpon="in_galpon"
						:isInventory="isInventory"
					/>
				</b-th>

				<b-th class="p-1"><DiferentsFieldStatus :specimen="specimen" :isInventory="isInventory" /></b-th>

				<b-th class="p-1">
					<b-form-datepicker
						:id="`date-${index}`"
						placeholder="Fecha"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						v-model="specimen.date_status"
						:disabled="'inventory-specimens-by-id-sr' == $route.name ? !specimen.check : false"
					/>
				</b-th>

				<b-th v-if="!isInventory" class="text-center p-50">
					<feather-icon
						icon="Trash2Icon"
						class="text-danger cursor-pointer"
						@click="deleteRooster(specimen, index)"
					/>
				</b-th>
			</b-tr>
		</b-tbody>
	</b-table-simple>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import SelectStatusRooster from "./SelectStatusRooster.vue"
import DiferentsFieldStatus from "./DiferentsFieldStatus.vue"
import moment from "moment"
import { avatarText } from "@core/utils/filter"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	name: "ChangeStatusMassively",
	components: {
		SelectStatusRooster,
		DiferentsFieldStatus,
		SpecimenPlate,
	},
	props: {
		in_galpon: {
			type: Number,
			default: null,
		},
		isInventory: {
			type: Boolean,
			default: false,
		},
		is_header: {
			type: Boolean,
		},
	},
	setup() {
		return {
			avatarText,
		}
	},
	async created() {
		// console.log("aqui:...")
		// await this.selectedSpecimensStatus.map(async (sp, index) => {
			// await this.getAllStatusSpecimens({ category_id: sp.category_id, index })
		// })
	},
	computed: {
		...mapState("statusSpecimens", ["arrayStatus", "selectedSpecimensStatus", "arrayImagesSpecimen"]),
		// isInventory() {
		// 	if (["inventory-specimens-list-sr", "inventory-specimens-by-id-sr"].includes(this.$route.name))
		// 		return false;
		// 	return true;
		// },
	},
	data() {
		return {
			status_global: null,
			responsable_global: null,
			date_general: moment().format("YYYY-MM-DD"),
			indexImage: null,
			checkAll: false,
		}
	},
	methods: {
		...mapMutations("statusSpecimens", [
			"DELETE_SELECTED_SPECIMENS_STATUS",
			"CLEAN_SPECIMENS",
			"M_SET_INDEX_IMAGES",
			"M_TOGGLE_CHECK_SPECIMEN",
			"M_SET_NEW_STATUS_SPECIMEN",
		]),
		...mapActions("statusSpecimens", ["getAllStatusSpecimens"]),
		deleteRooster(specimen, index) {
			this.DELETE_SELECTED_SPECIMENS_STATUS({ specimen, index })
		},
		checkAllTotal() {
			this.checkAll = !this.checkAll
			// console.log("checkAll", this.checkAll);
			this.selectedSpecimensStatus.forEach((specimen, index) => {
				this.M_TOGGLE_CHECK_SPECIMEN({ check: !specimen.check, key: index })
			})
		},
	},
	watch: {},
}
</script>

<style lang="scss" scoped>
.muda-gray {
	filter: grayscale(2) !important;
}
// [dir="ltr"] .custom-checkbox.custom-control,
// [dir="ltr"] .custom-radio.custom-control {
// 	padding-left: 0;
// }
.check-container {
	width: 60px !important;
	// background: red !important;
	// text-align: center;
	padding: 0;
	border: none;
	.check {
		padding: 1rem;
		// background: red;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 64.28px;
		width: 100%;
		.input {
			margin-left: -1rem;
		}
	}
}
.table-change {
	// background: red;
	min-width: 1200px;
}

.table-change::-webkit-scrollbar {
	width: 4px !important;
	background: #d5d1fb !important;
}

.table-change::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}
</style>
