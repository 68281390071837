var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isInventory)?_c('b-container',{staticClass:"container-item w-100"},[_c('b-row',{staticClass:"p-0 m-0 w-100"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Estado Global"}},[_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Estado Global'),expression:"'Estado Global'",modifiers:{"hover":true}}],attrs:{"value":_vm.globalChanges.new_status_id},on:{"input":function($event){return _vm.M_UPDATE_CHANGES({
							key: 'new_status_id',
							value: $event,
						})}}},[_vm._v(" >"),_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" -- Selecciona un estado -- ")]),_vm._l((_vm.arrayStatus),function(status,index){return _c('b-form-select-option',{key:("status-global-" + index),attrs:{"value":status.id}},[_vm._v(" "+_vm._s(status.description))])})],2)],1)],1),([6, 9, 10, 12, 13].includes(_vm.globalChanges.new_status_id))?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Responsable Global"}},[_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Responsable Global'),expression:"'Responsable Global'",modifiers:{"hover":true}}],attrs:{"value":_vm.globalChanges.responsable_id},on:{"input":function($event){return _vm.M_UPDATE_CHANGES({
							key: 'responsable_id',
							value: $event,
						})}}},[_vm._v(" >"),_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" -- Selecciona Responsable -- ")]),_vm._l((_vm.arrayUsers),function(responsable,index){return _c('b-form-select-option',{key:("responsable-globlal-" + index),attrs:{"value":responsable.id}},[_vm._v(" "+_vm._s(responsable.name))])})],2)],1)],1):_vm._e(),(_vm.globalChanges.new_status_id === 3)?_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Razon"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayReasons,"clearable":false,"label":"reason","reduce":function (option) { return option.id; }},on:{"input":function($event){return _vm.M_UPDATE_CHANGES({
							key: 'reason_death_id',
							value: $event,
						})}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{style:(("margin-left: " + (option.parent_id ? '10' : '0') + "px"))},[_vm._v(_vm._s(option.reason)+" ")]),(option.parent_id)?_c('span',[_vm._v(" Sub-motivo de "+_vm._s(option.parent_reason)+" ")]):_vm._e()])]}}],null,false,3821092383)})],1)],1):_vm._e(),([2, 4, 5, 11].includes(_vm.globalChanges.new_status_id))?[_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Cliente o Beneficiaro"}},[_c('v-select',{attrs:{"value":_vm.globalChanges.client_id,"clearable":false,"label":"name","options":_vm.arrayClients,"reduce":function (option) { return option.id; }},on:{"input":function($event){return _vm.M_UPDATE_CHANGES({
								key: 'client_id',
								value: $event,
							})}}})],1)],1),([2].includes(_vm.globalChanges.new_status_id))?_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Precio"}},[_c('b-form-input',{attrs:{"label":"name","type":"text","placeholder":"0"},on:{"keyup":function($event){return _vm.justNumbersGlobal()},"blur":function($event){return _vm.fixedCostGlobal()}},model:{value:(_vm.priceLocal),callback:function ($$v) {_vm.priceLocal=$$v},expression:"priceLocal"}})],1)],1):_vm._e()]:_vm._e(),(_vm.globalChanges.new_status_id == 6)?_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Razon"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value":_vm.globalChanges.disease_id,"options":_vm.arrayDiseases,"clearable":false,"label":"name","reduce":function (option) { return option.id; }},on:{"input":function($event){return _vm.M_UPDATE_CHANGES({
							key: 'disease_id',
							value: $event,
						})}}})],1)],1):_vm._e(),(
				(_vm.globalChanges.reason_death_id === 19 || _vm.globalChanges.disease_id === 1) &&
				[6, 3].includes(_vm.globalChanges.new_status_id)
			)?_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Otros"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"id":"others_description","type":"text","placeholder":"Ingrese otra razon"},on:{"input":function($event){return _vm.M_UPDATE_CHANGES({
								key: 'others_description',
								value: $event,
							})}}})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"2"}},[_c('b-form-group',{attrs:{"label":"Fecha Global"}},[_c('b-form-datepicker',{attrs:{"placeholder":"Fecha","date-format-options":{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					},"value":_vm.globalChanges.date_status},on:{"input":function($event){return _vm.M_UPDATE_CHANGES({
							key: 'date_status',
							value: $event,
						})}}})],1)],1)],2),_vm._t("process")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }