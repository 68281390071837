<template>
	<b-container v-if="isInventory" class="container-item w-100">
		<b-row  class="p-0 m-0 w-100">
			<b-col cols="12" md="3">
				<b-form-group label="Estado Global">
					<b-form-select
						:value="globalChanges.new_status_id"
						@input="
							M_UPDATE_CHANGES({
								key: 'new_status_id',
								value: $event,
							})
						"
						v-b-tooltip.hover="'Estado Global'"
					>
						><b-form-select-option :value="null"> -- Selecciona un estado -- </b-form-select-option>
						<b-form-select-option
							:value="status.id"
							v-for="(status, index) in arrayStatus"
							:key="`status-global-${index}`"
						>
							{{ status.description }}</b-form-select-option
						></b-form-select
					>
				</b-form-group></b-col
			>
			<b-col v-if="[6, 9, 10, 12, 13].includes(globalChanges.new_status_id)" cols="12" md="3">
				<b-form-group label="Responsable Global">
					<b-form-select
						:value="globalChanges.responsable_id"
						@input="
							M_UPDATE_CHANGES({
								key: 'responsable_id',
								value: $event,
							})
						"
						v-b-tooltip.hover="'Responsable Global'"
					>
						><b-form-select-option :value="null"> -- Selecciona Responsable -- </b-form-select-option>
						<b-form-select-option
							:value="responsable.id"
							v-for="(responsable, index) in arrayUsers"
							:key="`responsable-globlal-${index}`"
						>
							{{ responsable.name }}</b-form-select-option
						></b-form-select
					>
				</b-form-group></b-col
			>

			<b-col cols="12" md="2" v-if="globalChanges.new_status_id === 3"
				><b-form-group id="input-group-1" label="Razon">
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="arrayReasons"
						:clearable="false"
						label="reason"
						:reduce="(option) => option.id"
						@input="
							M_UPDATE_CHANGES({
								key: 'reason_death_id',
								value: $event,
							})
						"
					>
						<template v-slot:option="option">
							<div class="d-flex justify-content-between align-items-center">
								<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`"
									>{{ option.reason }}
								</span>
								<span v-if="option.parent_id"> Sub-motivo de {{ option.parent_reason }} </span>
							</div>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<!-- VENDIDO O DONADO -->
			<template v-if="[2, 4, 5, 11].includes(globalChanges.new_status_id)">
				<b-col cols="12" md="2">
					<b-form-group label="Cliente o Beneficiaro">
						<v-select
							:value="globalChanges.client_id"
							:clearable="false"
							label="name"
							:options="arrayClients"
							:reduce="(option) => option.id"
							@input="
								M_UPDATE_CHANGES({
									key: 'client_id',
									value: $event,
								})
							"
						/>
					</b-form-group>
				</b-col>

				<b-col cols="12" md="2" v-if="[2].includes(globalChanges.new_status_id)">
					<b-form-group label="Precio">
						<b-form-input
							v-model="priceLocal"
							label="name"
							type="text"
							placeholder="0"
							@keyup="justNumbersGlobal()"
							@blur="fixedCostGlobal()"
						/>
					</b-form-group>
				</b-col>
			</template>

			<b-col cols="12" md="2" v-if="globalChanges.new_status_id == 6">
				<!-- ENFERMO -->
				<b-form-group id="input-group-1" label="Razon">
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="globalChanges.disease_id"
						:options="arrayDiseases"
						:clearable="false"
						label="name"
						:reduce="(option) => option.id"
						@input="
							M_UPDATE_CHANGES({
								key: 'disease_id',
								value: $event,
							})
						"
					/>
				</b-form-group>
			</b-col>

			<!-- OTHERS DESCRIPTION ENFERMO O MUERTO -->
			<b-col
				cols="12"
				md="2"
				v-if="
					(globalChanges.reason_death_id === 19 || globalChanges.disease_id === 1) &&
					[6, 3].includes(globalChanges.new_status_id)
				"
				><b-form-group label="Otros">
					<b-input-group>
						<b-form-textarea
							id="others_description"
							@input="
								M_UPDATE_CHANGES({
									key: 'others_description',
									value: $event,
								})
							"
							type="text"
							placeholder="Ingrese otra razon"
						></b-form-textarea>
					</b-input-group> </b-form-group
			></b-col>

			<!-- <b-col cols="12" md="2"></b-col> -->

			<b-col cols="12" md="3" lg="2"
				><b-form-group label="Fecha Global">
					<b-form-datepicker
						@input="
							M_UPDATE_CHANGES({
								key: 'date_status',
								value: $event,
							})
						"
						placeholder="Fecha"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						:value="globalChanges.date_status"
					/> </b-form-group
			></b-col>
		</b-row>
		<slot name="process"></slot>
	</b-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
	name: "GlobalChangesStatusMassively",
	components: {},
	props: {},
	created() {},
	mounted() {},
	destroyed() {
		this.M_CLEAN_GLOBAL_CHANGES();
	},
	data() {
		return {
			priceLocal: null,
		};
	},
	computed: {
		...mapState("statusSpecimens", [
			"arrayReasons",
			"arrayClients",
			"arrayDiseases",
			"arrayUsers",
			"arrayStatus",
			"globalChanges",
		]),
		isInventory() {
			if (["inventory-specimens-list-sr", "inventory-specimens-by-id-sr"].includes(this.$route.name))
				return false;
			return true;
		},
	},
	methods: {
		...mapMutations("statusSpecimens", ["M_UPDATE_CHANGES", "M_CLEAN_GLOBAL_CHANGES"]),
		justNumbersGlobal() {
			if (this.priceLocal) {
				this.priceLocal = this.justNumbers(this.priceLocal);
			}
			this.M_UPDATE_CHANGES({
				key: "price",
				value: this.priceLocal,
			});
		},
		fixedCostGlobal() {
			if (this.priceLocal && Number(this.priceLocal)) {
				this.priceLocal = this.fixedMoney(this.priceLocal);
				this.M_UPDATE_CHANGES({
					key: "price",
					value: this.priceLocal,
				});
			}
		},
	},
	watch: {},
};
</script>

<style lang="scss" scoped>
.container-btn {
	// position: absolute;
	// top: 50%;
	// right: 0;
	// transform: translateY(-50%) translateX(-50px);
	width: auto;
}
.container-item {
	position: "relative";
	padding: 0;
	margin: 1rem 1rem;
	width: 100%;
	background: transparent;
}
</style>
