var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card p-2"},[_c('div',{staticClass:"mx-2 m-2 position-relative"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start justify-content-sm-start",attrs:{"cols":"12","md":"12","lg":"4"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" Mostrando "+_vm._s(_vm.start_page)+" a "+_vm._s(_vm.to_page)+" de "+_vm._s(_vm.total_data)+" registros ")])]),_c('b-col',{staticClass:"flex-nowrap",attrs:{"cols":"12","md":"12","lg":"8"}},[_c('div',{staticClass:"flex flex-column align-items-end"},[_c('div',{staticClass:"xt-search-input__paginate mt-1 mt-md-0"},[_c('b-pagination',{attrs:{"value":_vm.page,"total-rows":_vm.total_data,"per-page":_vm.perpage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (e) { return _vm.SET_FILTERS({
											search: _vm.search,
											page: e,
										}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}])})],1),_c('div',{staticClass:"xt-search-input__search w-100"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-end justify-content-md-end"},[_c('b-form-input',{staticClass:"search-input",staticStyle:{"width":"200px"},attrs:{"type":"search","placeholder":"Buscar cintillo"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.init($event)},"input":function($event){return _vm.SET_FILTERS({
											search: _vm.searchInput,
											page: _vm.page,
										})}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('div',{staticClass:"search-btn ml-0 ml-md-1 mt-1 mt-md-0"},[_c('button',{staticClass:"btn btn-primary w-100",on:{"click":_vm.init}},[_vm._v("Buscar")])])],1)])])])],1)],1)]),_c('div',{staticClass:"card p-2 flex-row"},[_c('button',{staticClass:"btn btn-primary mr-1",on:{"click":_vm.globalDeadStatusModalView}},[_vm._v("Matar masivo")]),_c('button',{staticClass:"btn btn-success",on:{"click":_vm.addCintillosToInventory}},[_vm._v("Encontrados")])]),_c('div',{staticClass:"card"},[(_vm.chicks.length > 0)?[_c('div',{staticClass:"container-chicks-match p-2"},_vm._l((_vm.chicks),function(chick,index){return _c('CardChickInventory',{key:index,attrs:{"check_disabled":"","chick":chick,"index":index},on:{"resurrect":_vm.resurrect,"dead":_vm.deadChick}})}),1)]:[_vm._m(0)]],2),(_vm.selectDeadChick.open)?_c('AddDeadCPCA',{attrs:{"postura":{
			ca: {
				id: _vm.selectDeadChick.ca.id,
				plate: _vm.selectDeadChick.ca.plate,
				status: _vm.selectDeadChick.ca.status,
			},
			cintillos: {
				cp_id: null,
			},
			egg_lote_id: null,
		}},on:{"set-reload":function($event){_vm.changeReloadRecursive = true},"hidden":_vm.closeAddDeadCPCA}}):_vm._e(),(_vm.globalDeadStatusModal)?_c('DeadsMassivelyChicksCA',{attrs:{"chicksDeads":_vm.chicksDeads,"open":_vm.globalDeadStatusModal,"recursive":false,"status_id":3,"hide_select_chicks":""},on:{"refresh":_vm.refreshDeadGlobal}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mt-2 mb-2"},[_c('p',{staticClass:"font-small-4 mb-0 font-weight-bolder"},[_vm._v("Sin ejemplares por mostrar")])])}]

export { render, staticRenderFns }