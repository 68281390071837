<template>
	<b-form-group
		label="Nuevo Estado"
		:label-for="`status-${specimen.specimen_id}`"
		class="d-flex flex-column align-items-center"
	>
		<ValidationProvider
			:rules="!isInventory || specimen.check ? 'required' : ''"
			v-slot="{ errors }"
			class="d-flex flex-column align-items-center"
		>
			<!-- <b-form-select
				:id="`status-${specimen.specimen_id}`"
				v-model=""
				:options="
					
				"
				value-field="id"
				text-field="description"
				:state="errors[0] ? false : null"
				:disabled="isInventory ? !specimen.check : false"
				:selectable="(opt) => opt.selectable"
			></b-form-select> -->
	
			<v-select
				v-model="specimen.new_status_id"
				:options="
					is_header
						? filteredArrayStatus.filter((it) => it.id == 3)
						: filteredArrayStatus.filter((it) => (in_galpon == null ? true : it.in_galpon == in_galpon))
				"
				label="description"
				class=""
				:reduce="(val) => val.id"
				placeholder="Seleccionar opción"
				:selectable="(val) => val.selectable || val.selectable == undefined"
				:state="errors[0] ? false : null"
				:disabled="isInventory ? !specimen.check : false"
				style="width: 300px"
			/>
		</ValidationProvider>
	</b-form-group>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
export default {
	name: "SelectStatusRooster",
	components: {},
	props: {
		specimen: {
			type: Object,
			required: true,
		},
		in_galpon: {
			type: Number,
			default: null,
		},
		isInventory: {
			type: Boolean,
			default: false,
		},
		is_header: {
			type: Boolean,
		},
		index: {
			type: Number,
			default: null,
		},
	},
	created() {},
	mounted() {
		this.filteringArrayStatus()
		// this.is_header && (this.specimen.new_status_id = 3);
		// this.is_header && (this.specimen.new_status_id = 3);
		this.specimen.specimen_id &&
			this.is_header &&
			this.M_SET_NEW_STATUS_SPECIMEN({ id: this.specimen.specimen_id, new_status_id: 3 })
	},
	data() {
		return {
			filteredArrayStatus: [],
		}
	},
	computed: {
		...mapState("statusSpecimens", [, "arrayStatus"]),
		// isInventory () {
		//   if ('inventory-specimens-by-id-sr' == this.$route.name) return false;
		//   return true;
		// }
	},
	methods: {
		...mapActions("statusSpecimens", ["getReasons"]),
		...mapMutations("statusSpecimens", ["M_SET_NEW_STATUS_SPECIMEN"]),
		filteringArrayStatus() {
			this.filteredArrayStatus = this.specimen.statuses.filter((status) => status.id !== this.specimen.status_id)
			this.filteredArrayStatus = this.filteredArrayStatus.filter((status) => status.id !== 16)
			this.filteredArrayStatus = this.filteredArrayStatus.map((it) => ({
				...it,
				selectable: true,
				description: `------ ${it.description}`,
			}))

			this.filteredArrayStatus.unshift({
				id: null,
				description: "DISPONIBLES",
				selectable: false,
			})

			const indexInactivos = this.filteredArrayStatus.findIndex((it) => it.in_galpon == 0)

			// console.log("index", indexInactivos)

			this.filteredArrayStatus.splice(indexInactivos, 0, {
				id: null,
				description: "NO DISPONIBLES",
				selectable: false,
			})
		},
	},
	watch: {
		async "specimen.new_status_id"(newValue) {
			const currentstatus = this.filteredArrayStatus.find((st) => st.id == newValue)
			if (currentstatus) {
				this.specimen.new_is_reason = currentstatus.is_reason
				if (currentstatus.is_reason == 1) {
					this.isPreloading()
					try {
						await this.getReasons({ module_id: 1, status_id: newValue, index: this.index })
					} catch (error) {
						console.log("error-reasons: ", error)
					} finally {
						this.isPreloading(false)
					}
				}
			}

			if (newValue === this.specimen.status_id) {
				this.specimen.new_status_id = null
			}
		},
	},
}
</script>

<style></style>
