var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[(_vm.isInventory)?_c('div',{staticClass:"card mb-0 border-top-2 pb-50 pl-1"},[_c('ul',{staticClass:"nav nav-tabs align-items-center gap-x-1 mb-0 p-50",attrs:{"role":"tabslits"}},[_c('b-nav-item',{staticClass:"x-tab-width",attrs:{"link-classes":"px-1 py-25 justify-content-between","active":_vm.spec.tab == 0},on:{"click":function($event){_vm.spec.tab = 0}}},[_c('span',[_vm._v("Crear")])]),_c('b-nav-item',{staticClass:"x-tab-width",attrs:{"link-classes":"px-1 py-25 justify-content-between","active":_vm.spec.tab == 1},on:{"click":function($event){_vm.spec.tab = 1}}},[_c('span',[_vm._v("Actualizar")])])],1)]):_vm._e(),_c('b-tabs',{attrs:{"content-class":"p-2 pr-4","nav-wrapper-class":"card pt-1 pr-1 pl-1 d-none","nav-class":"align-items-center","lazy":""},model:{value:(_vm.spec.tab),callback:function ($$v) {_vm.$set(_vm.spec, "tab", $$v)},expression:"spec.tab"}},[_c('b-tab',{staticClass:"content-tab"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'origin-' + _vm.index,"label":"Origen"}},[_c('ValidationProvider',{attrs:{"rules":_vm.spec.check || !_vm.isInventory ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"placeholder":"Seleccionar ","options":_vm.arrayOrigins,"clearable":false,"id":'origin-' + _vm.index,"label":"name","reduce":function (option) { return option.id; },"disabled":_vm.isInventory ? !_vm.spec.check : false},on:{"input":function($event){;(_vm.spec.color_padrillo_id = null), (_vm.spec.color_madrilla_id = null)}},model:{value:(_vm.spec.origin_id),callback:function ($$v) {_vm.$set(_vm.spec, "origin_id", $$v)},expression:"spec.origin_id"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'category-' + _vm.index,"label":"Categoría"}},[_c('ValidationProvider',{attrs:{"rules":_vm.spec.check || !_vm.isInventory ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"placeholder":"Seleccionar","options":_vm.arrayCategories,"clearable":false,"id":'category-' + _vm.index,"label":"description","reduce":function (option) { return option.id; },"disabled":_vm.isInventory ? !_vm.spec.check : false},model:{value:(_vm.spec.category_id),callback:function ($$v) {_vm.$set(_vm.spec, "category_id", $$v)},expression:"spec.category_id"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'plate-' + _vm.index,"label":"Placa"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isInventory,"id":'plate-' + _vm.index,"state":errors[0] ? false : null,"placeholder":"Escribe una placa"},model:{value:(_vm.spec.plate),callback:function ($$v) {_vm.$set(_vm.spec, "plate", $$v)},expression:"spec.plate"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),(![1, null].includes(_vm.spec.origin_id))?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Linea del ejemplar (" + _vm.spec.lines.length + ")"}},[_c('b-button',{staticClass:"mb-05",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){;(_vm.breederLineModal.show = !_vm.breederLineModal.show),
									(_vm.breederLineModal.info = { lines: _vm.spec.lines })}}},[_c('FeatherIcon',{staticClass:"mx-1 cursor-pointer",attrs:{"icon":"Edit3Icon","size":"18"}})],1),_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.spec.lines),expression:"spec.lines"}],staticClass:"hidden",domProps:{"value":(_vm.spec.lines)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.spec, "lines", $event.target.value)}}}),_c('br'),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,686163995)})],1)],1):_vm._e(),(![1, null].includes(_vm.spec.origin_id))?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Raza del ejemplar (" + _vm.spec.races.length + ")"}},[_c('b-button',{staticClass:"mb-05",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){;(_vm.raceModal.show = !_vm.raceModal.show),
									(_vm.raceModal.info = { races: _vm.spec.races, creating: true })}}},[_c('FeatherIcon',{staticClass:"mx-1 cursor-pointer",attrs:{"icon":"Edit3Icon","size":"18"}})],1),_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.spec.lines),expression:"spec.lines"}],staticClass:"hidden",domProps:{"value":(_vm.spec.lines)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.spec, "lines", $event.target.value)}}}),_c('br'),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,686163995)})],1)],1):_vm._e(),(_vm.spec.origin_id != 1 && _vm.spec.origin_id != null)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'addi-plate-' + _vm.index,"label":"Placa adicional"}},[_c('b-form-input',{attrs:{"id":'addi-plate-' + _vm.index,"placeholder":"Escribe una placa adicional","disabled":_vm.isInventory ? !_vm.spec.check : false},model:{value:(_vm.spec.addi_plate),callback:function ($$v) {_vm.$set(_vm.spec, "addi_plate", $$v)},expression:"spec.addi_plate"}})],1)],1):_vm._e(),(_vm.spec.origin_id == 1 && _vm.spec.origin_id != null)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'dob-' + _vm.index,"label":"Nacimiento"}},[_c('ValidationProvider',{attrs:{"rules":_vm.spec.check || (!_vm.isInventory && _vm.spec.origin_id == 1) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control bg-transparent",class:{ 'border-danger rounded': errors[0] },attrs:{"id":'dob' + _vm.index,"placeholder":"Elige una Fecha","config":_vm.datepickerConf},model:{value:(_vm.spec.dob),callback:function ($$v) {_vm.$set(_vm.spec, "dob", $$v)},expression:"spec.dob"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3063020680)})],1)],1):_vm._e(),(_vm.spec.origin_id != 1 && _vm.spec.origin_id != null)?_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'color-' + _vm.index,"label":"Criador"}},[_c('v-select',{attrs:{"placeholder":"Seleccionar ","options":_vm.S_BREEDERS,"label":"name","reduce":function (option) { return option.id; }},model:{value:(_vm.spec.breeder_id),callback:function ($$v) {_vm.$set(_vm.spec, "breeder_id", $$v)},expression:"spec.breeder_id"}})],1)],1):_vm._e(),([1, 2].includes(_vm.spec.origin_id) && _vm.spec.origin_id != null)?[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'color-' + _vm.index,"label":"Padrillo"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('SelectSpecimens',{class:{ 'border-danger rounded': errors[0] },attrs:{"specimen":_vm.spec,"valid_dob":_vm.spec.origin_id == 1,"categories":"1"},on:{"selected":function($event){return _vm.setSpecimen($event)}},model:{value:(_vm.padrillo),callback:function ($$v) {_vm.padrillo=$$v},expression:"padrillo"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,439584130)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label-for":'color-' + _vm.index,"label":"Madrilla"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('SelectSpecimens',{class:{ 'border-danger rounded': errors[0] },attrs:{"specimen":_vm.spec,"valid_dob":_vm.spec.origin_id == 1,"categories":"2"},on:{"selected":function($event){return _vm.setSpecimen($event, true)}},model:{value:(_vm.madrilla),callback:function ($$v) {_vm.madrilla=$$v},expression:"madrilla"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,2766634056)})],1)],1)]:_vm._e(),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Medidas del ejemplar"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){;(_vm.measureModal.show = !_vm.measureModal.show),
									(_vm.measureModal.info = { races: _vm.spec.measurements, creating: true })}}},[_c('FeatherIcon',{staticClass:"mx-1 cursor-pointer",attrs:{"icon":"Edit3Icon","size":"18"}})],1),(_vm.spec.measurements && _vm.spec.measurements.length != 0)?_c('span',[_vm._v(" "+_vm._s(_vm.spec.measurements.length)+" Medidas asignadas ")]):_vm._e()],1)],1),(_vm.arraySpecs.length - 1 == _vm.index && !_vm.isInventory)?_c('div',{staticClass:"position-absolute",staticStyle:{"bottom":"12px","right":"12px","z-index":"100"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.$emit('addNewSpec')}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"18"}})],1)],1):_vm._e()],2)],1),_c('b-tab',{style:({ width: '100%' })},[_c('UpdatePlateAndStatusSpecimen',{attrs:{"spec":_vm.spec,"isInventory":_vm.isInventory}})],1)],1),_c('AddBreederLineModal',{attrs:{"show":_vm.breederLineModal.show,"info":_vm.breederLineModal.info},on:{"closing":function($event){_vm.breederLineModal.show = false},"new-line":function ($event) { return (_vm.spec.lines = $event); }}}),_c('UpdateRaceModal',{attrs:{"show":_vm.raceModal.show,"info":_vm.raceModal.info},on:{"closing":function($event){_vm.raceModal.show = false},"new-line":function ($event) { return (_vm.spec.races = $event); }}}),_c('AddMeasures',{attrs:{"show":_vm.measureModal.show,"info":_vm.measureModal.info},on:{"closing":function($event){_vm.measureModal.show = false},"new-measures":function ($event) { return (_vm.spec.measurements = $event); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }