var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[([6, 9, 10, 12, 13].includes(_vm.specimen.new_status_id))?_c('b-form-group',{attrs:{"label":"Responsable","label-for":("responsable-" + (_vm.specimen.specimen_id))}},[_c('ValidationProvider',{attrs:{"rules":_vm.specimen.new_status_id == 10 && (!_vm.isInventory || _vm.specimen.check) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":("responsable-" + (_vm.specimen.specimen_id)),"options":_vm.arrayUsers,"value-field":"id","text-field":"name","state":errors[0] ? false : null,"disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.responsable_id),callback:function ($$v) {_vm.$set(_vm.specimen, "responsable_id", $$v)},expression:"specimen.responsable_id"}})]}}],null,false,2895178577)})],1):_vm._e(),(_vm.specimen.new_status_id == 9 || _vm.specimen.new_status_id == 10)?_c('b-form-group',{attrs:{"label":"Peso","label-for":("weight-" + (_vm.specimen.specimen_id))}},[_c('ValidationProvider',{attrs:{"rules":!_vm.isInventory || _vm.specimen.check ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"£","label-for":"weight"}},[_c('b-form-input',{attrs:{"id":("weight-" + (_vm.specimen.specimen_id)),"type":"number","placeholder":"0","state":errors[0] ? false : null,"disabled":_vm.isInventory ? !_vm.specimen.check : false},on:{"input":function($event){return _vm.convertPounds(_vm.specimen)}},model:{value:(_vm.specimen.weight),callback:function ($$v) {_vm.$set(_vm.specimen, "weight", $$v)},expression:"specimen.weight"}})],1)]}}],null,false,476609703)})],1):_vm._e(),([2, 4, 5, 11].includes(_vm.specimen.new_status_id))?[_c('ValidationProvider',{attrs:{"rules":[2, 4, 5, 11].includes(_vm.specimen.new_status_id) && (!_vm.isInventory || _vm.specimen.check) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cliente o Beneficiaro","label-for":("client-" + (_vm.specimen.specimen_id))}},[_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"id":("client-" + (_vm.specimen.specimen_id)),"clearable":false,"label":"name","options":_vm.arrayClients,"reduce":function (option) { return option.id; },"disabled":_vm.isInventory ? !_vm.specimen.check : false,"state":errors[0] ? false : null},model:{value:(_vm.specimen.client_id),callback:function ($$v) {_vm.$set(_vm.specimen, "client_id", $$v)},expression:"specimen.client_id"}})],1)]}}],null,false,1368100243)}),([2].includes(_vm.specimen.new_status_id))?_c('b-form-group',{attrs:{"label":"Precio","label-for":"price "}},[_c('ValidationProvider',{attrs:{"rules":!_vm.isInventory || _vm.specimen.check ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","label":"name","type":"text","placeholder":"0","state":errors[0] ? false : null,"disabled":_vm.isInventory ? !_vm.specimen.check : false},on:{"keyup":function($event){return _vm.justNumbersToFront(_vm.specimen)},"blur":function($event){return _vm.fixedCost(_vm.specimen)}},model:{value:(_vm.specimen.price),callback:function ($$v) {_vm.$set(_vm.specimen, "price", $$v)},expression:"specimen.price"}})]}}],null,false,2715007847)})],1):_vm._e()]:_vm._e(),(_vm.specimen.new_is_reason == 1)?[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Motivo","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":!_vm.isInventory || _vm.specimen.check ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.specimen.reasons,"clearable":false,"label":"reason","reduce":function (option) { return option.id; },"disabled":_vm.isInventory ? !_vm.specimen.check : false},on:{"input":function () {
							_vm.specimen.others_description = ''
						}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{style:(("margin-left: " + (option.parent_id ? '10' : '0') + "px"))},[_vm._v(" "+_vm._s(option.reason)+" ")]),(option.parent_id)?_c('span',[_vm._v("Sub-motivo de "+_vm._s(option.parent_reason))]):_vm._e()])]}}],null,true),model:{value:(_vm.specimen.reason_death_id),callback:function ($$v) {_vm.$set(_vm.specimen, "reason_death_id", $$v)},expression:"specimen.reason_death_id"}})]}}],null,false,3246818054)})],1),(_vm.specimen.reason_death_id === 19)?_c('b-form-group',{attrs:{"label":"Otros","label-for":"others_description"}}):_vm._e(),_c('b-form-group',{attrs:{"id":"input-group-1","label":"Evidencias","label-for":"input-1"}},[_c('drag-and-drop-images',{attrs:{"filesArray":_vm.specimen.evidences,"source":("flyers-" + (_vm.specimen.specimen_id)),"containerTitle":"Arrastra las evidencias aquí","containerDescription":"Selecciona uno","containerPadding":"10px"},model:{value:(_vm.specimen.evidences),callback:function ($$v) {_vm.$set(_vm.specimen, "evidences", $$v)},expression:"specimen.evidences"}})],1)]:_vm._e(),(
			(_vm.specimen.reason_death_id === 19 || _vm.specimen.disease_id === 1) &&
			[6, 3].includes(_vm.specimen.new_status_id)
		)?_c('b-form-group',{attrs:{"label":"Otros","label-for":"others_description"}},[_c('ValidationProvider',{attrs:{"rules":!_vm.isInventory || _vm.specimen.check ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
		var errors = ref.errors;
return [_c('b-input-group',{attrs:{"label-for":"others_description"}},[_c('b-form-textarea',{attrs:{"id":"others_description","type":"text","placeholder":"Ingrese otra razon","state":errors[0] ? false : null,"disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.others_description),callback:function ($$v) {_vm.$set(_vm.specimen, "others_description", $$v)},expression:"specimen.others_description"}})],1)]}}],null,false,1373938887)})],1):_vm._e(),(_vm.specimen.new_status_id !== 3 && _vm.specimen.disease_id !== 1)?_c('b-form-group',{attrs:{"label":"Descripción","label-for":("observation-" + (_vm.specimen.specimen_id))}},[_c('b-form-textarea',{attrs:{"id":("observation-" + (_vm.specimen.specimen_id)),"type":"text","placeholder":"Descripción","disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.specimen.description),callback:function ($$v) {_vm.$set(_vm.specimen, "description", $$v)},expression:"specimen.description"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }