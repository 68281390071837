<template>
	<div>
		<custom-light-box
			:items="arrayImagesSpecimens"
			:index="indexImages"
			:effect="'fade'"
			@close="M_SET_INDEX_IMAGES(null)"
		/>
		<HeaderMassivelyChanges
			v-if="!isInventory && !isSpecimen"
			:selectedSpecimens="selectedSpecimensStatus"
			@selected="selected"
			@save="saveStatusMassively"
		/>

		<transition name="fade" mode="out-in" v-if="!is_header">
			<b-card body-class="p-0 m-0" v-if="selectedSpecimensStatus.length && !isSpecimen">
				<GlobalChangesStatusMassively>
					<template v-slot:process>
						<slot name="btn-process"></slot>
					</template>
				</GlobalChangesStatusMassively>
			</b-card>
		</transition>

		<b-card body-class="" class="container-change mt-2">
			<transition name="fade" mode="out-in">
				<div v-if="!selectedSpecimensStatus.length" class="">
					<h5 class="mb-0 text-center">Aun no se encuentran Ejemplares</h5>
				</div>
				<ValidationObserver v-else tag="div" ref="form">
					<ChangeStatusMassively :is_header="is_header" :in_galpon="in_galpon" :isInventory="isInventory" />
				</ValidationObserver>
			</transition>
		</b-card>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import HeaderMassivelyChanges from "../components/HeaderMassivelyChanges.vue"
import ChangeStatusMassively from "./ChangeStatusMassively.vue"
import GlobalChangesStatusMassively from "./GlobalChangesStatusMassively.vue"
import vSelect from "vue-select"

export default {
	name: "MainStates",
	components: {
		vSelect,
		ChangeStatusMassively,
		GlobalChangesStatusMassively,
		HeaderMassivelyChanges,
	},
	props: {
		in_galpon: {
			type: Number,
			default: null,
		},
		isInventory: {
			type: Boolean,
			default: false,
		},
		is_header: {
			type: Boolean,
			default: false,
		},
		isSpecimen: {
			type: Boolean,
			default: false,
		},
	},
	async created() {
		await Promise.all([
			this.getUsersByRole(),
			// this.getAllStatusSpecimens({ category_id: this.selectedSpecimensStatus[0].category_id ?? null }),
			this.getReasons({ module_id: 1 }),
			this.getDiseases(),
			this.getClients(),
		])
		this.M_INIT({ init: true })
	},
	destroyed() {
		this.SET_CLEAR_ARRAYS()
	},
	data() {
		return {
			selectedRooster: null,
			arrayCategorysSpecimens: [
				{
					id: 1,
					name: "Padrillo",
					status: false,
					category: "padrillo_id",
				},
				{
					id: 2,
					name: "Madrilla",
					status: false,
					category: "madrilla_id",
				},
				{
					id: 3,
					name: "Gallo",
					status: true,
					category: "gallo_id",
				},
				{
					id: 4,
					name: "Gallina",
					status: false,
					category: "gallina_id",
				},
				{
					id: 5,
					name: "Pollon",
					status: false,
					category: "pollon_id",
				},
				{
					id: 6,
					name: "Polla",
					status: false,
					category: "polla_id",
				},
			],
		}
	},
	computed: {
		...mapState("statusSpecimens", [
			"arrayAvailableSpecimens",
			"selectedSpecimensStatus",
			"indexImages",
			"arrayImagesSpecimens",
			"category_id",
		]),
		// isInventory() {
		// 	if (["inventory-specimens-list-sr", "inventory-specimens-by-id-sr"].includes(this.$route.name)) return false;
		// 	return true;
		// },
	},

	methods: {
		...mapActions("statusSpecimens", [
			"getAllStatusSpecimens",
			"getReasons",
			"getClients",
			"getDiseases",
			"getUsersByRole",
			"getAvailableSpecimens",
			"A_SAVE_STATUS_MASSIVELY",
		]),
		...mapMutations("statusSpecimens", [
			"PUSH_FILTER_ARRAY_AVAILABLE_SPECIMENS",
			"SET_CLEAR_ARRAYS",
			"M_SET_INDEX_IMAGES",
			"M_SET_CATEGORY_ID",
			"M_INIT",
		]),
		selected(selected) {
			this.PUSH_FILTER_ARRAY_AVAILABLE_SPECIMENS(selected)
		},
		async saveStatusMassively() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campor por llenar"
				)
				return
			}
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro de actualizar los estados de ${this.selectedSpecimensStatus.length} ejemplares?`,
			})
			if (!confirm.value) return
			this.isPreloading()
			try {
				// console.log('add 1');
				await this.A_SAVE_STATUS_MASSIVELY()
				setTimeout(() => {
					this.$emit("hidden")
					this.isPreloading(false)
				}, 300)
				// console.log('add 2');
			} catch (error) {
				this.showErrorSwal(error)
			} finally {
			}
		},
		fuseSearch(options, search) {
			const fuse = new Fuse(options, {
				keys: ["plate", "alias"],
				shouldSort: true,
			})
			return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
		},

		async getAvailableSpecimensbyCategory(category) {
			this.isPreloading()
			this.arrayCategorysSpecimens.forEach((item) => {
				item.status = false
			})
			let newCategory = null
			if (this.category_id !== category.id) {
				category.status = true
				newCategory = category.id
			}
			this.M_SET_CATEGORY_ID(newCategory)
			try {
				await this.getAvailableSpecimens()
			} catch (error) {
				this.showErrorSwal(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.card-sticky-global {
	position: sticky;
	top: -2rem;
	z-index: 999;
}
.pointer-none {
	pointer-events: none;
}
.container-btns {
	position: sticky;
	top: 0;
}
.container-change {
	overflow: auto;
}
</style>
